import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, ElementRef} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, LayoutUtilsService, LoaderService, PageScopeService, StoreService } from '../../../shared/services';
import { ModalDialogComponent } from '../../../shared/components/custom-dialog/custom-dialog.component';
import { ModalViewDialogComponent } from '../../../shared/components/custom-view-dialog/custom-view-dialog.component';
import { CustomSelectAutocompleteComponent } from '../../../shared/components/custom-select-autocomplete/custom-select-autocomplete.component';

import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { UsableUser } from '../../../shared/services/models/usable-user';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-custom-metadata-table',
  templateUrl: './custom-metadata-table.component.html',
  styleUrls: ['./custom-metadata-table.component.scss']
})
export class CustomMetadataTableComponent implements OnInit {
	private subscriptions: Subscription[] = [];
  public roleList = environment.customDisplayKeys;
  public customKeys = environment.customKeys;
	public permission: any[] = [environment.customKeys.roleAdmin, environment.customKeys.roleFaculty,environment.customKeys.roleSupervisor];
  public errorMessage: string = '';
  public scopes: string[] = [];
  normalDisplay: string[] = ['string', 'integer', 'phone', 'number', 'email', 'text'];
  tableData: Array<any> = [];
  tableDataTotal: number = 0;

  selection = new SelectionModel<any>(true, []);
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns = [];
  /* pagination Info */
  pageSize = 10;
  pageNumber = 1;

  orderDir = 'asc';
  public storedFilters = undefined;
  public metaFieldSetting = undefined;
  public originalTableSetting: any = undefined;
  public customBubbleFilters = [];
  public creatable: boolean = false;
  public pagination: boolean = true;
  public deletable: boolean = false;
  public editable: boolean = false;
  public defaultOrder: any[] = []; // need handeling
  public searchVal: string = '';

  public selectedDataName: any = undefined;
  public selectedDatafilters: any = undefined;
  public dataTypeFilter: string = 'filterquery';
  public dataTypeDisplayFilter: string = 'Filter Query';
  public filterObject: any = {'$and': []};
  public filterCompetenciesObject: any = [];
  public innerWidth: any = 300;

  @Input() orderBy: string = 'uid';
  @Input() orderByList: string[] = [];
  @Input() targetName: string = '';
  @Input() isPreview: boolean = false;
  @Input() canStoreFilter: boolean = true;
  @Input() canSort: boolean = true;
  @Input() canMultiSelect: boolean = false;
  @Input() canNew: boolean = false;
  @Input() canFilter: boolean = false;
  @Input() canSelectFilter: boolean = false;
  @Input() canExport: boolean = false;
  @Input() customFilter: any = undefined;
  @Input() canSearch: boolean = true;
  @Input() canRefresh: boolean = true;
  @Input() title: string = '';
  @Input() tableName: string = 'default';
  @Input() dataType: string;
  @Input() dataTypeDisplay: string = 'Data';
  public _loading: boolean = false;
  @Input()
  set loading(loading: boolean) {
    this._loading = loading;
    this.dataLoading.next(loading);
  }
  get loading(): boolean {
    return this._loading;
  }
  public dataLoading: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean>(false);
  public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  @Input() fieldsToHide: string[] = [];
  public _tableSetting: any = { columns: []};
  @Input()
  set tableSetting(tableSetting: any) {
    this._tableSetting = tableSetting;
    this.setFilterStored();
    if(tableSetting){
      // console.log('tableSetting', tableSetting);
      this.creatable = tableSetting.creatable;
      this.pageSize = tableSetting.defaultCount;
      // this.pagination = tableSetting.pagination;
      this.deletable = tableSetting.deletable;
      this.editable = tableSetting.editable;

      if(this.metaFieldSetting){
        this.customBubbleFilters = this.buildfilterBuble();
      }else{
        this.buildSetting();
      }
      let displayedColumns = [];
      if (tableSetting.fields.length > 0) {
        if (this.canMultiSelect) {
          displayedColumns.push('select'); // remove this and add at config level
        }
        for (let col of tableSetting.fields) {
          if (col.visible && !this.fieldsToHide.includes(col.name)) {
            if(col.parent !== ''){
              displayedColumns.push(col.parent + '.' + col.name);
            }else{
              displayedColumns.push(col.name);
            }
          }
        }
      }
      this.displayedColumns = displayedColumns;
      this.loadData();
    }else{
      this.clear();
    }
  }
  get tableSetting(): any {
    return this._tableSetting;
  }
  @Output() onSelectFilter = new EventEmitter<any>();
  @Output() onClickBtn = new EventEmitter<any>();
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('customselectautocomplete') customselectautocomplete: CustomSelectAutocompleteComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild('sort1') sort: MatSort;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('myIdentifier') myIdentifier: ElementRef;
  constructor(
    public store: StoreService, private translate: TranslateService,
    private loaderService: LoaderService,
    private pageScopeService: PageScopeService,
    private requestService: RequestService,
    public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
    private changeDetectorRefs: ChangeDetectorRef
  ) { }
  // ngAfterViewInit() {
  //   console.log(this.myIdentifier.nativeElement.offsetWidth);
  //   this.innerWidth = this.myIdentifier.nativeElement.offsetWidth;
  // }
  ngOnInit() {
    this.innerWidth = this.myIdentifier.nativeElement.offsetWidth;
    // Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			// tslint:disable-next-line:max-line-length
			debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
			distinctUntilChanged(), // This operator will eliminate duplicate values
			tap(() => {
				this.paginator.pageIndex = 0;
        this.getTableVariables();
        this.loadData();
			})
		)
		.subscribe();
		this.subscriptions.push(searchSubscription);
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (
      this.paginator.pageIndex = 0
    ));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
    - when a pagination event occurs => this.paginator.page
    - when a sort event occurs => this.sort.sortChange
    **/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => {
        this.getTableVariables();
				this.loadData();
			})
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

    this.subscriptions.push(
      this.pageScopeService.menuSelectedScope.subscribe( (scopes) => {
          this.scopes = scopes
        }
      )
    );
		// First Load
		// of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
		// 	this.loadData();
		// });
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          let selectedrole = this.store.get('selectedrole');
          let filter = {'$and': []};
          filter['$and'].push({'roleuid': { '$eq': selectedrole.uid }});
          filter['$and'].push({'target': { '$eq': this.targetName }});
          this.selectedDatafilters = filter;
        }
      })
    );
  }

  setFilterStored(){
    let keyData = this.tableName + '-' + this.dataType;
    this.storedFilters = this.store.get('storedFilters');
    if(this.storedFilters){
      if(this.storedFilters.hasOwnProperty(keyData)){
        this.searchVal = this.storedFilters[keyData].term;
        this.pageNumber = this.storedFilters[keyData].page;
        this.orderDir = this.storedFilters[keyData].orderbydir;
        this.orderBy = this.storedFilters[keyData].orderby;
        this.pageSize = this.storedFilters[keyData].perpage;
      }
    }
  }
  updateFilterStored(storedFilters){
    if(this.canStoreFilter){
      let keyData = this.tableName + '-' + this.dataType;
      this.storedFilters = {};
      this.storedFilters[keyData] = storedFilters;
      this.store.set('storedFilters', this.storedFilters);
    }
  }

  /** FILTRATION */
	termConfiguration(): any {
		// const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		// filter.lastName = searchText;
    //
		// filter.name = searchText;
		// filter.email = searchText;
		// filter.firstname = searchText;
		return searchText;
	}
  refresh(): void {
    this.loadData();
  }
  clear(): void {
    this.tableData = [];
    this.tableDataTotal = 0;
    this.dataSource.data = [];
    this.paginatorTotal.next(0);
  }
  public getTableVariables() {
    this.orderBy = this.sort.active || 'uid';
    this.orderDir = this.sort.direction || 'asc';
    this.pageNumber = this.paginator.pageIndex + 1;
    this.pageSize = this.paginator.pageSize;
  }
  private getfields(){
    let fields: string[] = []
    for(let fld of this.tableSetting.fields){
      if(fld.type !== 'action' && fld.type !== 'uid'){
        if(fld.parent !== ''){
          fields.push(fld.parent + '.' + fld.name);
        }else{
          fields.push(fld.name);
        }
      }
    }
    return fields;
  }
  private buildfilterBuble(){
    let bubbles = [];
    let filterConfiguration = this.getfilter();
    if(filterConfiguration){
      let presetFields = this.getPresetFields();
      for(let flt of filterConfiguration['$and']){
        let fltKey = Object.keys(flt)[0];
        let fltData = flt[fltKey];
  			let fltKeyName = fltKey.replace(/\$/g, '');
        let fltArrayKeys = fltKeyName.split('.');
        if(fltKey === '$or'){ // specific for program and phd
          let subflt = fltData[0];
					let subfltKeys = Object.keys(subflt);
					fltKey = subfltKeys[0];
    			fltKeyName = fltKey.replace(/\$/g, '');
        }
        if(!presetFields.includes(fltKey)){
          if(fltArrayKeys.length > 1){
            bubbles.push({name: fltKey, displayName: fltArrayKeys[1], parent: fltArrayKeys[0], value: ''});
          }else{
            bubbles.push({name: fltKey, displayName: fltKeyName, parent: undefined, value: ''});
          }
        }
      }
    }
    let filterCompetenciesConfiguration = this.getcompetenciesfilter();
    if(filterCompetenciesConfiguration){
      bubbles.push({name: 'competencies', displayName: this.translate.instant('competencies'), parent: undefined, value: ''});
    }
    // console.log('buildfilterBuble', bubbles);
    return bubbles;
  }
  private getfilter(){
    if(this.tableSetting.hasOwnProperty('filters')){
      return this.tableSetting.filters;
    }
    return undefined;
  }
  private getcompetenciesfilter(){
    if(this.tableSetting.hasOwnProperty('competencies')){
      if(this.tableSetting['competencies'].length > 0){
        return this.tableSetting.competencies;
      }
    }
    return undefined;
  }
  private getPresetFields(){
    if(this.tableSetting.hasOwnProperty('presetFields')){
      return this.tableSetting.presetFields;
    }
    return [];
  }
  public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let termConfiguration = this.termConfiguration();
      let fieldsConfiguration = this.getfields();
      let filterConfiguration = this.getfilter();
      let filterCompetenciesConfiguration = this.getcompetenciesfilter();
      let filterObj = {page: this.pageNumber , term: termConfiguration, perpage: this.pageSize, orderbydir: this.orderDir, orderby: this.orderBy, orderByList: this.orderByList,  filter: filterConfiguration, competencies: filterCompetenciesConfiguration, fieldKeys: fieldsConfiguration};
      this.updateFilterStored(filterObj);
      this.requestService.getDataList(this.dataType, filterObj, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.tableData = data.results;
          // this.dataSource = new MatTableDataSource<any>(data.results);
          //
          // this.dataSource.sort = this.sort;
          // console.log('this.tableSetting', this.tableSetting);
          // console.log('this.dataSource', data.results);
          this.dataSource.data = data.results;
          if (data.pagination) {
            this.tableDataTotal = data.pagination.total;
            this.paginatorTotal.next(data.pagination.total);
          }
        } else {
          this.tableData = [];
          this.dataSource.data = [];
        }
        this.loading = false;
        this.changeDetectorRefs.detectChanges();
      });
    }
  }
  public sortData(e) {
    console.log(e);
  }
  // public alterDataTable() {
  //   this.dataSource = new MatTableDataSource<UsableUser>(this.tableData);
  //   this.dataSource.sort = this.sort;
  //   this.changeDetectorRefs.detectChanges();
  // }
  public toggleModal(event: any) {
    if (event.action && event.action === 'refresh') {
      this.loadData();
    }
  }
  public filterIt() {
    // this.customselectautocomplete.clearData(); // this will clear the autocomplete upon clicking filter btn
    this.toggleClick('filter', 'parent', undefined, undefined);
  }
  public exportIt() {
    this.toggleClick('export', 'parent', undefined, undefined);
  }
  public clearFilterIt() {
    this.customselectautocomplete.clearData();
    this.toggleClick('clearfilter', 'parent', undefined, undefined);
  }
  public removeFilter(name) {
    this.customselectautocomplete.clearData();
    if(name === 'program'){
      this.toggleClick('removefilter', 'parent', '$or', undefined);
    }else{
      this.toggleClick('removefilter', 'parent', name, undefined);
    }
  }
  public create(event: any) {
    if (this.tableSetting.target === 'parent') {
      this.toggleClick('create', 'parent', this.getEmptyObject(), undefined);
    } else {
      const dialogRef = this.dialog.open(ModalDialogComponent, {
          width: '640px',
    			data: {
            dataType: this.dataType,
            dataTypeTitle: this.dataTypeDisplay,
            title: this.translate.instant('Create') + ' ' + this.dataTypeDisplay,
            data: {},
            modalSetting: Object.assign({}, this.tableSetting)
          }
    		});
      dialogRef.afterClosed().subscribe(result => {
  			if (result) {
          this.toggleModal(result);
        }
      });
    }
  }
  private getEmptyObject() {
    let newObj = {};
    for (let col of this.tableSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.dataType === 'number') {
          newObj[col.name] = 0;
        } else {
          newObj[col.name] = '';
        }
      }
    }
    return newObj;
  }
  public toggleClick(action, target, data, index) {
    if (target === 'parent') {
      this.onClickBtn.emit({action: action, data: data, index: index});
    } else {
      console.log('toggleClick Self', action, target, data, index);
      if (action === 'modal') {
      } else if (action === 'edit') {
        const dialogRef = this.dialog.open(ModalDialogComponent, {
            width: '640px',
      			data: {
              dataType: this.dataType,
              dataTypeTitle: this.dataTypeDisplay,
              title: this.translate.instant('Edit') + ' ' + this.dataTypeDisplay,
              data: {uid: data.uid},
              modalSetting: Object.assign({}, this.tableSetting)
            }
      		});
        dialogRef.afterClosed().subscribe(result => {
    			if (result) {
            console.log('result', result);
            this.toggleModal(result);
          }
        });
      } else if (action === 'view') {
        const dialogRef = this.dialog.open(ModalViewDialogComponent, {
            width: '1600px',
      			data: {
              dataType: this.dataType,
              title: this.translate.instant(data.name),
              data: {uid: data.uid},
              modalSetting: Object.assign({}, this.tableSetting)
            }
      		});
        dialogRef.afterClosed().subscribe(result => {
    			if (result) {
            console.log('result', result);
            this.toggleModal(result);
          }
        });
      } else if (action === 'delete') {
        this.delete(data.uid);
      }
    }
  }
  public delete(id: any) {
    if (!this.loading) {
      const _title: string = this.dataTypeDisplay + ' ' + this.translate.instant('Deletion');
  		const _description: string = this.translate.instant('Are you sure you want to permanently delete this') + ' ' + this.dataTypeDisplay + '?';
      const _waitDesciption: string = this.translate.instant('Deleting') + '...';

  		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
  		dialogRef.afterClosed().subscribe(res => {
  			if (!res) {
  				return;
  			}
        this.realDelete(id);
  		});
    }
	}
  public realDelete(id: any) {
    const _deleteMessage = this.dataTypeDisplay + ' ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData(this.dataType, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
    			this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
          this.loadData();
        }
      });
    }
  }
  /**
	  * Check all rows are selected
	  */
	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.tableData.length;
		return numSelected === numRows;
	}
  /**
	  * Toggle selection
	  */
	masterToggle() {
		if (this.selection.selected.length === this.tableData.length) {
			this.selection.clear();
		} else {
			this.tableData.forEach(row => this.selection.select(row));
		}
	}
  /**
   * Fetch selected rows
   */
  // fetchData() {
  // 	const messages = [];
  // 	this.selection.selected.forEach(elem => {
  // 		messages.push({
  // 			text: `${elem.fullname}, ${elem.email}`,
  // 			id: elem.id.toString(),
  // 			status: elem.username
  // 		});
  // 	});
  // 	this.layoutUtilsService.fetchElements(messages);
  // }

  applyFilter(filterValue: string) {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
      this.dataSource.filter = filterValue;
      if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
      }
  }
  private buildSetting() {
      this.requestService.getMetaData(this.dataType, undefined, (data, error) => {
        if (error) {
          console.log('getMetaData', error);
        }
        if (data) {
          this.originalTableSetting = data.results;
          this.metaFieldSetting = this.buildMetaSetting(data.results, parent = undefined);
          // console.log('this.metaFieldSetting', this.metaFieldSetting);
          this.customBubbleFilters = this.buildfilterBuble();
        }
      });
  }
  buildMetaSetting(data, parent = undefined){
    let dataObject = {};
    // let tabObject = [];
    for (let col of data.fields){
      if((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table'){
        if(parent){
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      }else if (col.type === 'object'){
        dataObject[col.name] = this.buildMetaSetting(col);
        // tabObject.push({name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name)});
      }
      else if (col.type === 'table'){
        // dataObject[col.name] = this.buildMetaSetting(col);
        // tabObject.push({name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name)});
        dataObject[col.name] = col;
      }
    }
    // this.metaFieldTabsSetting = tabObject;
    return dataObject;
  }
  public setSelectedDataName(val) {
    this.selectedDataName = val;
		if (this.selectedDataName.uid) {
			if (!this.loading) {
				this.loading = true;
				this.errorMessage = '';
				this.requestService.getSingleData(this.dataTypeFilter, this.selectedDataName.uid, (data, error) => {
					if (error) {
						this.errorMessage = error;
						this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
					}
					if (data) {
						// console.log('data', data.results);
						if(data.results.filter){
							this.cleanFilterObject(JSON.parse(data.results.filter));
							this.cleanFilterCompetenciesObject(JSON.parse(data.results.competencies));

              this.onSelectFilter.emit({ export: false, selectedDataId: this.selectedDataName.uid, filter: this.filterObject, filterCompetencies: this.filterCompetenciesObject, fields: data.results.fields});
						}
					}
					this.loading = false;
				});
			}
		}
  }
  cleanFilterObject(filter = undefined){
		let copiedItem = JSON.parse(JSON.stringify( this.filterObject));
		if(filter && filter.hasOwnProperty('$and')){
			this.filterObject['$and'] = filter['$and'];
		}else{
			this.filterObject['$and'] = [];
		}
		if(copiedItem.hasOwnProperty('$and')){
      let presetFields = this.getPresetFields();
			let idx = 0;
			for(let flt of copiedItem['$and']){
				let fltKey = Object.keys(flt)[0];
				if(presetFields.includes(fltKey)){
					this.filterObject['$and'].push(flt);
				}
				idx++;
			}
		}
	}
	cleanFilterCompetenciesObject(filter = undefined){
		if(filter){
			this.filterCompetenciesObject = filter;
		}else{
			this.filterCompetenciesObject = [];
		}
	}
}
