import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';

// import { GlobalService } from './services/global.service';

import { SidebarComponent, TopnavComponent, NavComponent, PageHeaderComponent, SnackBarComponent, CustomTableComponent, CustomMetadataTableComponent,ActionNotificationComponent, AlertComponent, DeleteEntityDialogComponent, FetchEntityDialogComponent, UpdateStatusDialogComponent, AlertEntityDialogComponent, UserFormComponent,CustomFormComponent, LoadingScreenComponent, ModalDialogComponent, CustomSelectComponent, ModalViewDialogComponent, ModalVideoViewDialogComponent, ModalUserViewDialogComponent, ModalDocumentViewDialogComponent, CustomTagsComponent, EnumViewComponent, OfferingModalDialogComponent, RegisterationModalDialogComponent, ErrorEntityDialogComponent, ModalPasswordDialogComponent, ConfirmEntityDialogComponent, ModalFilterDialogComponent, YearsProgramViewComponent, ModalExportDialogComponent, CustomSelectAutocompleteComponent, CustomMultipleSelectComponent, ModalPreviewDialogComponent, CustomConfirmEntityDialogComponent, CustomMultipleTableSelectComponent, ModalGalleryDialogComponent, ModalFilterExportDialogComponent} from './components';
import { ModalMultipleAttachmentsDialogComponent, AlertActionEntityDialogComponent } from './components';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        ImageCropperModule,
        FlexLayoutModule.withConfig({addFlexToParent: false})
    ],
    providers: [
        // GlobalService
    ],
    entryComponents: [
        SnackBarComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        UserFormComponent,
        CustomFormComponent,
        LoadingScreenComponent,
        ModalDialogComponent,
        OfferingModalDialogComponent,
        RegisterationModalDialogComponent,
        ModalViewDialogComponent,
        ModalVideoViewDialogComponent,
        ModalDocumentViewDialogComponent,
        ModalUserViewDialogComponent,
        ModalPasswordDialogComponent,
        CustomSelectComponent,
        EnumViewComponent,
        ModalFilterDialogComponent,
        ModalExportDialogComponent,
        ModalFilterExportDialogComponent,
        CustomSelectAutocompleteComponent,
        CustomMultipleSelectComponent,
        YearsProgramViewComponent,
        ModalPreviewDialogComponent,
        CustomConfirmEntityDialogComponent,
        CustomMultipleTableSelectComponent,
        ModalGalleryDialogComponent,
        ModalMultipleAttachmentsDialogComponent,
        AlertActionEntityDialogComponent
    ],
    declarations: [
        SidebarComponent,
        TopnavComponent,
        NavComponent,
        PageHeaderComponent,
        SnackBarComponent,
        CustomTableComponent,
        CustomTagsComponent,
        EnumViewComponent,
        CustomMetadataTableComponent,
        ActionNotificationComponent,
        AlertActionEntityDialogComponent,
        ModalDialogComponent,
        OfferingModalDialogComponent,
        RegisterationModalDialogComponent,
        ModalViewDialogComponent,
        ModalVideoViewDialogComponent,
        ModalDocumentViewDialogComponent,
        ModalUserViewDialogComponent,
        AlertComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        ModalPasswordDialogComponent,
        UserFormComponent,
        CustomFormComponent,
        LoadingScreenComponent,
        CustomSelectComponent,
        ModalFilterDialogComponent,
        ModalExportDialogComponent,
        ModalFilterExportDialogComponent,
        CustomSelectAutocompleteComponent,
        CustomMultipleSelectComponent,
        YearsProgramViewComponent,
        ModalPreviewDialogComponent,
        CustomConfirmEntityDialogComponent,
        CustomMultipleTableSelectComponent,
        ModalGalleryDialogComponent,
        ModalMultipleAttachmentsDialogComponent
    ],
    exports: [
        SidebarComponent,
        TopnavComponent,
        NavComponent,
        PageHeaderComponent,
        ActionNotificationComponent,
        AlertActionEntityDialogComponent,
        ModalDialogComponent,
        OfferingModalDialogComponent,
        RegisterationModalDialogComponent,
        ModalViewDialogComponent,
        ModalVideoViewDialogComponent,
        ModalDocumentViewDialogComponent,
        ModalUserViewDialogComponent,
        AlertComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        ModalPasswordDialogComponent,
        CustomTableComponent,
        CustomTagsComponent,
        EnumViewComponent,
        CustomMetadataTableComponent,
        UserFormComponent,
        CustomFormComponent,
        LoadingScreenComponent,
        CustomSelectComponent,
        ModalFilterDialogComponent,
        ModalExportDialogComponent,
        ModalFilterExportDialogComponent,
        CustomSelectAutocompleteComponent,
        CustomMultipleSelectComponent,
        YearsProgramViewComponent,
        ModalPreviewDialogComponent,
        CustomConfirmEntityDialogComponent,
        CustomMultipleTableSelectComponent,
        ModalGalleryDialogComponent,
        ModalMultipleAttachmentsDialogComponent
    ]
})
export class LayoutComponentModule { }
