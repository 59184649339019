export const environment = {
  production: false,
  serverUrl: 'https://smt.dev.ili.ms/api/',
  iliWebUrl: 'https://smt.webapp.interactivelife.me/pages/',
  casUrl: 'https://test-cas.rutgers.edu/',
  casService: 'https://smt.dev.ili.ms/',
  // serverUrl: 'https://api.medio.interactivelife.me/v1',
  profileImageURL: '/assets/profile/',
  serverName: 'Rutgers',
  subServerName: 'Graduate School of Applied and Professional Psychology',
  customKeys: {
    roleAdmin: 'RL-UkwtMQ==',
    roleSchoolStudent: 'RL-UkwtNA==',
    roleAlumni: 'RL-UkwtNw==',
    roleFaculty: 'RL-UkwtMw==',
    roleStaff: 'RL-UkwtNQ==',
    roleSupervisor: 'RL-UkwtNg=='
  },
  customDisplayKeys: {
    'RL-UkwtMQ==': 'Admin',
    'RL-UkwtNA==': 'Student',
    'RL-UkwtNw==': 'Alumni',
    'RL-UkwtMw==': 'Faculty',
    'RL-UkwtNQ==': 'Staff',
    'RL-UkwtNg==': 'Supervisor'
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
