import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RequestService, LayoutUtilsService } from '../../../shared/services';

@Component({
	selector: 'app-password-dialog-modal',
	templateUrl: './custom-password-dialog.component.html',
  styleUrls: ['./custom-password-dialog.component.scss']
})
export class ModalPasswordDialogComponent implements OnInit {
	public errorMessage: string = '';
  public loading: boolean = false;
  public oldpassword: string = '';
  public password: string = '';
  public newpassword: string = '';
	constructor(
		private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalPasswordDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
      // console.log('UserViewDialogData', data);
	}
	ngOnInit() {
	}
  public saveData() {
		if(this.oldpassword !== '' && this.newpassword !== '' &&  this.newpassword === this.password){
			if (!this.loading) {
				this.loading = true;
				this.requestService.editUserPassword(this.oldpassword, this.newpassword, (data, error) => {
					if (error) {
						this.errorMessage = error;
						this.oldpassword = '';
						this.newpassword = '';
						this.password = '';
						this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
						this.loading = false;
						return;
					}
					if (data) {
						this.layoutUtilsService.showNotification('Your password has been changed successfully.', 'Dismiss');
					} else {
						this.layoutUtilsService.showNotification('Something went wrong. Please try again.', 'Dismiss');
					}
					this.newpassword = '';
					this.oldpassword = '';
					this.password = '';
					this.loading = false;
					this.closeModal();
				});
		 }
		}else{
			this.layoutUtilsService.showNotification('The passwords you entered do not match.', 'Dismiss');
		}
  }
  closeModal(): void {
    this.dialogRef.close();
  }
}
