import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { RequestService, LayoutUtilsService, LoaderService, StoreService } from '../../../shared/services';
import { CustomConfirmEntityDialogComponent } from '../modals/';
import { CustomSelectAutocompleteComponent } from '../../../shared/components/custom-select-autocomplete/custom-select-autocomplete.component';

interface DialogData {
	dataType: string;
	targetName: string;
	subDataType: string[];
	preFieldsObject: string[];
	title: string;
	data: any;
	selectedDataId: any;
	modalSetting: any;
	presetFields: any;
	confirmData: any;
}

@Component({
	selector: 'export-dialog-modal',
	templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class ModalExportDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public errorMessage: string = '';
  public loading: boolean = false;
	public selectedUser: any;
	public normalDisplay: string[] = ['string', 'phone', 'email', 'textarea', 'text'];
	public hiddenFields: string[] = ['phd'];
  public hasFormErrors: boolean = false;
  public metaFieldSetting = undefined;
  public filterAdded: boolean = false;
	public dataType: string = 'filterfield';
  public dataTypeDisplay: string = 'Export Template';
	public dataTypeFilter: string = 'filterquery';
  public dataTypeDisplayFilter: string = 'Select Filter';
  public filterObject: any = {'$and': []};
  public filterCompetenciesObject: any = [];
  public fieldsObject: any[] = [];
  public selectedfield: string[] = [];
  public fieldObject: any = {};
  public fieldList: any[] = [];
  public fieldListOriginal: any[]= [];
  public selectedDataName: any = undefined;
  public selectedFilter: any = undefined;
	public selectedDatafilters: any = undefined;
  // public fieldObject: any = {};
  @ViewChild('customselectautocomplete') customselectautocomplete: CustomSelectAutocompleteComponent;
	constructor(private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService, public store: StoreService,
		public dialogRef: MatDialogRef<ModalExportDialogComponent>,
			public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: DialogData) {
			this.fieldsObject = this.data.preFieldsObject;
			// this.fieldsObject = this.predefinedFields(this.data.data);
			// this.fieldsObject = this.predefinedFields(this.buildfilterBuble(this.data.data));
	}

	ngOnInit() {
			this.subscriptions.push(
        this.requestService.currentUserSubject.subscribe((data) => {
          if (data) {
            this.selectedUser = data;
	          let selectedrole = this.store.get('selectedrole');
						let filter = {'$and': []};
						filter['$and'].push({'roleuid': { '$eq': selectedrole.uid }});
						filter['$and'].push({'target': { '$eq': this.data.targetName }});
						this.selectedDatafilters = filter;
						this.loadData();
          }
        })
      );
	}
	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	public predefinedFields(bubbles){
		let fieldsObject = JSON.parse(JSON.stringify(this.fieldsObject));
		for(let fld of bubbles){
			if(!fieldsObject.includes(fld.fltKeyName)){
				fieldsObject.push(fld.fltKeyName);
			}
		}
		return fieldsObject;
	}
	public loadData() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
				}
				if (data) {
					const copiedItem = JSON.parse(JSON.stringify( data.results.fields));
					this.fieldList = this.prepareFields(copiedItem);
					this.fieldListOriginal = JSON.parse(JSON.stringify(this.fieldList));
					this.metaFieldSetting = this.buildMetaSetting(data.results);
				} else {
					const copiedItem = JSON.parse(JSON.stringify(this.data.modalSetting.fields));
					this.fieldList = this.prepareFields(copiedItem);
					this.fieldListOriginal = JSON.parse(JSON.stringify(this.fieldList));
					this.metaFieldSetting = this.buildMetaSetting(this.data.modalSetting);
				}
				// this.cleanSelectorsObject();
				this.cleanSelectorsObject();
				this.loading = false;
				if(this.data.selectedDataId){
					this.exportSelectedFilter(this.data.selectedDataId);
				}
			});
		}
  }
	public prepareFields(fields, parent = undefined, parentName = undefined) {
		let fieldList: any[] = [];
		let fieldBuffer: any[] = [];
			for( let fld of fields){
				// if(fld.visible && fld.searchable){
				if(fld.visible && fld.searchable && !(this.data.presetFields.includes(fld.name) && fld.parent === "") && fld.type !== 'image' && fld.type !== 'table'){
					if(parent && parentName){
						fld['parent'] = parent; // after uday added parent
						fld['parentName'] = parentName; // after uday added parent
						fld['inputName'] = parent + '.' + fld['name'];
					}else{
						fld['inputName'] = fld['name'];
					}
					fld['disabled'] = false;
					fieldList.push(fld);
					this.fieldObject[fld.inputName] = fld;
				}else if(fld.type === 'object' && this.data.subDataType.includes(fld.name)){
					let subData = this.prepareFields(fld.fields, fld.name, fld.displayName);
					// fieldList = fieldList.concat(subData);
					fieldBuffer.push({name: fld.displayName, data: subData});
				}
			}
			for(let bf of fieldBuffer){
				fieldList.push({displayName: bf.name, inputName: '', disabled: true})
				fieldList = fieldList.concat(bf.data);
			}
			return fieldList;
  }
	public selectField(val) {
		this.selectedfield = val;
  }
	public addNewFilter() {
		// if(this.selectedfield.length > 0){
			let copiedItem = JSON.parse(JSON.stringify(this.fieldList));
			copiedItem = copiedItem.filter((fld) => {
					if(this.selectedfield.includes(fld.inputName)){
						this.fieldsObject.push(fld.inputName);
						return false;
					}else{
						return true;
					}
				}
			);
			this.fieldList =  JSON.parse(JSON.stringify(copiedItem));
			this.selectedfield = [];
		// }else{
		// 	this.layoutUtilsService.showNotification('Select a field to add', 'Dismiss');
		// }
  }
	public removeFilter(idx) {
		let currentSelect = this.fieldsObject[idx];
		let idx1 = 0;
		for(let fld of this.fieldListOriginal){
			if(currentSelect === fld.inputName){
				this.fieldList.splice(idx1, 0, fld);
				break;
			}
			idx1++;
		}
		this.fieldsObject.splice(idx, 1);
  }
  closeModal(data): void {
		if(data.export){
			if(this.fieldsObject.length > 0){
				if(this.selectedFilter){
					this.dialogRef.close({preview: data.preview, fieldsObject: this.fieldsObject, filter: this.filterObject, filterCompetencies: this.filterCompetenciesObject});
				}else{
					this.dialogRef.close({preview: data.preview, fieldsObject: this.fieldsObject, filter: undefined, filterCompetencies: undefined});
				}
			}else{
				this.layoutUtilsService.showNotification('No fields selected to export', 'Dismiss');
			}
		}else{
			this.dialogRef.close(undefined);
		}
  }
	cleanSelectorsObject(){
		let idx = 0;
		for( let fld of this.fieldList){
			if(this.fieldsObject.includes(fld.inputName)){
				this.fieldList.splice(idx, 1);
			}
			idx++;
		}
	}
	buildMetaSetting(data, parent = undefined){
    let dataObject = {};
    let tabObject = [];
    for (let col of data.fields){
      if((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table'){
        if(parent){
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      }else if (col.type === 'object'){
        dataObject[col.name] = this.buildMetaSetting(col);
        tabObject.push({name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name)});
      }else if (col.type === 'table'){
        dataObject[col.name] = this.buildMetaSetting(col);
      }
    }
    return dataObject;
  }
	saveFilter(dataObject){
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			let dataUid = dataObject.hasOwnProperty('uid') ? dataObject.uid : undefined;
			this.requestService.saveData(this.dataType, dataObject, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
				}
				if (data) {
					if(dataUid){
						this.layoutUtilsService.showNotification('Export template Edited Successfully.', 'Dismiss');
					}else{
						this.layoutUtilsService.showNotification('Export template created Successfully.', 'Dismiss');
					}
				}
				this.loading = false;
			});
		}
	}
  createFilter(): void {
		if(this.fieldsObject.length > 0){
			if(this.selectedDataName && this.selectedDataName.text !== ''){
				let selectedrole = this.store.get('selectedrole');
				let dataObject = { name: this.selectedDataName.text, roleuid: selectedrole.uid, fields: this.fieldsObject , target: this.data.targetName};
				// if(this.selectedDataName.uid){
				// 	dataObject['uid'] = this.selectedDataName.uid;
				// }
				// this.saveFilter(dataObject);
				if(this.selectedDataName.uid){
					dataObject['uid'] = this.selectedDataName.uid;
					this.continuereateFilter(dataObject);
				}else{
					this.saveFilter(dataObject);
				}
			}else{
				this.layoutUtilsService.showNotification('Fill or select Export Template name', 'Dismiss');
			}
		}else{
			this.layoutUtilsService.showNotification('Add a field to create a new Export Template', 'Dismiss');
		}
  }
	continuereateFilter(dataObject): void {
		const dialogRef = this.dialog.open(CustomConfirmEntityDialogComponent, {
				width: '600px',
				autoFocus: false,
				data: {
					title: 'Save Export Template',
					description: 'Do you want to create a new Export Template or edit the selected one?',
					cancelbtn: 'Cancel',
					confirmbtn: 'Create',
					confirmbtnData: 'new',
					confirmbtnSecond: 'Edit Current Export Template',
					confirmbtnSecondData: 'edit'
				}
			});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				if(result === 'new'){
					this.customselectautocomplete.inputName.nativeElement.focus();
				}else{
					this.saveFilter(dataObject);
				}
			}
		});
  }
	public setSelectedDataName(val) {
    this.selectedDataName = val;
		if (this.selectedDataName.uid) {
			if (!this.loading) {
				this.loading = true;
				this.errorMessage = '';
				this.requestService.getSingleData(this.dataType, this.selectedDataName.uid, (data, error) => {
					if (error) {
						this.errorMessage = error;
						this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
					}
					if (data) {
						if(data.results.fields){
							this.fieldsObject = data.results.fields;
							this.fieldList = JSON.parse(JSON.stringify(this.fieldListOriginal));
						}else{
							this.fieldsObject = [];
							this.fieldList = JSON.parse(JSON.stringify(this.fieldListOriginal));
						}
						this.cleanSelectorsObject();
					}
					this.loading = false;
				});
			}
		}
  }
	public exportSelectedFilter(val) {
    this.selectedFilter = val;
		// console.log(val);
		if (this.selectedFilter) {
			if (!this.loading) {
				this.loading = true;
				this.errorMessage = '';
				this.requestService.getSingleData(this.dataTypeFilter, this.selectedFilter, (data, error) => {
					if (error) {
						this.errorMessage = error;
						this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
					}
					if (data) {
						// console.log('data', data.results);
						if(data.results.filter){
							this.fieldsObject = this.predefinedFields(this.buildfilterBuble(JSON.parse(data.results.filter)));
							this.cleanFilterObject(JSON.parse(data.results.filter));
							this.cleanFilterCompetenciesObject(JSON.parse(data.results.competencies));
							this.cleanSelectorsObject();
						}
					}
					this.loading = false;
				});
			}
		}else{
			this.filterObject = {'$and': []};
		  this.filterCompetenciesObject = [];
		}
  }
	cleanFilterObject(filter = undefined){
		let copiedItem = JSON.parse(JSON.stringify( this.filterObject));
		if(filter && filter.hasOwnProperty('$and')){
			this.filterObject['$and'] = filter['$and'];
		}else{
			this.filterObject['$and'] = [];
		}
		if(copiedItem.hasOwnProperty('$and')){
			let idx = 0;
			for(let flt of copiedItem['$and']){
				let fltKey = Object.keys(flt)[0];
				if(this.data.presetFields.includes(fltKey)){
					this.filterObject['$and'].push(flt);
				}
				idx++;
			}
		}
	}
	cleanFilterCompetenciesObject(filter = undefined){
		if(filter){
			this.filterCompetenciesObject = filter;
		}else{
			this.filterCompetenciesObject = [];
		}
	}
	private buildfilterBuble(filterConfiguration){
    let bubbles = [];
    // let filterConfiguration = this.filterObject;
		// console.log('filterConfiguration', filterConfiguration);
    if(filterConfiguration){
      let presetFields = this.data.presetFields;
      for(let flt of filterConfiguration['$and']){
        let fltKey = Object.keys(flt)[0];
        let fltData = flt[fltKey];
  			let fltKeyName = fltKey.replace(/\$/g, '');
        let fltArrayKeys = fltKeyName.split('.');
        if(fltKey === '$or'){ // specific for program and phd
          let subflt = fltData[0];
					let subfltKeys = Object.keys(subflt);
					fltKey = subfltKeys[0];
    			fltKeyName = fltKey.replace(/\$/g, '');
        }
        if(!presetFields.includes(fltKey)){
          if(fltArrayKeys.length > 1){
            bubbles.push({fltKeyName: fltKeyName, name: fltKey, displayName: fltArrayKeys[1], parent: fltArrayKeys[0], value: ''});
          }else{
            bubbles.push({fltKeyName: fltKeyName, name: fltKey, displayName: fltKeyName, parent: undefined, value: ''});
          }
        }
      }
    }
    return bubbles;
  }
}
