import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-custom-select-autocomplete',
  templateUrl: './custom-select-autocomplete.component.html',
  styleUrls: ['./custom-select-autocomplete.component.scss']
})
export class CustomSelectAutocompleteComponent implements OnInit {
  public errorMessage: string = '';
  public loading: boolean = false;
  public dataText: string = '';
  selectData: Array<any> = [];
  filteredData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  /* pagination Info */
  pageSize = 30;
  pageNumber = 1;
  orderDir = 'asc';
  orderBy = 'name';
  searchText = '';

  @Input() clearable: boolean = false;
  @Input() canDelete: boolean = false;
  @Input() filters: any;
  @Input() displayName: string[] = ['name', 'text'];
  @Input() placeholder: string = '';
  @Input() dataType: string;
  @Input() dataTypeDisplay: string;
  public _value: any = undefined;
  @Input()
  set value(value: any) {
    this._value = value;
    if(value && value.uid){
      this.dataText = JSON.parse(JSON.stringify(value));
    }else{
      this.dataText = '';
    }
  }
  get value(): any {
    return this._value;
  }
  @Output() onSelectReturn = new EventEmitter<any>();
  @ViewChild('inputName') inputName: ElementRef;
  constructor(
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService
  ) { }

  ngOnInit() {
    this.loadData();
  }
  private getSelectedItem(val){
    for(let itm of this.selectData){
      if(val === itm.uid){
        return itm
      }
    }
    return '';
  }
  displayFn(data?: any): string | undefined {
    return data ? data.text : undefined;
  }
  public clearData() {
    this.dataText = '';
  }
  public loadData() {
    this.loadDataSearch();
  }
  public loadDataSearch() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let filterConfiguration = this.filters;
      let include = undefined;
      if(this.value && this.value.uid){
        include = [];
        include.push(this.value.uid);
      }
      this.requestService.getDataListSummary(this.dataType, {page: this.pageNumber, orderbydir: this.orderDir, orderby: this.orderBy , term: this.searchText, perpage: this.pageSize, filter: filterConfiguration, include: include}, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        if (data) {
          this.selectData = data.results;
        } else {
          this.selectData = [];
        }
        this.filteredData.next(this.selectData);
        this.loading = false;
      });
    }
  }
  termSearch(term): any {
    this.searchText = term;
    this.onSelectReturn.emit({uid: undefined, text: term});
    this.loadDataSearch();
	}
  public setAttribute(val) {
    this.onSelectReturn.emit(val);
  }
  public deleteItem(e, val) {
    if(e){
      e.stopPropagation();
      e.preventDefault();
    }
    if (!this.loading) {
  		const _title: string = 'Delete ' + this.dataTypeDisplay;
  		const _description: string = 'Are you sure you want to permanently delete this ' + this.dataTypeDisplay + '?';
  		const _waitDesciption: string = 'Deleting...';

  		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
  		dialogRef.afterClosed().subscribe(res => {
  			if (!res) {
  				return;
  			}
        this.realDelete(val.uid);
  		});
    }
	}
  public realDelete(id: any) {
    const _deleteMessage = this.dataTypeDisplay + ` Deleted Successfully.`;
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData(this.dataType, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        this.loading = false;
        if (data) {
    			this.layoutUtilsService.showNotification(_deleteMessage, 'Dismiss');
          this.loadData();
        }
      });
    }
  }
}
