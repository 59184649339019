import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      // logged in so return true
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (this.legitUser(currentUser)) {
        return true;
      }
    }
    let queryParam = Object.assign({}, route.queryParamMap['params']);
    // ?ticket=ST-2610312-9QKqeyLxNB4AG7wwicL6-cas2A
    // https://test-cas.rutgers.edu/logout?service=http://smt.dev.ili.ms/
    queryParam['returnUrl'] = state.url;
    this.router.navigate(['/login'], { queryParams: queryParam });
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});

    return false;
  }
  private legitUser(user): boolean {
    if (user.hasOwnProperty('uid') && user.hasOwnProperty('token')) {
      return true;
    } else {
      return false;
    }
  }
}