import { Injectable } from '@angular/core';
import { CanDeactivate  } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<any>
{
    component: Object;
    route: ActivatedRouteSnapshot;
    constructor(){
    }
    canDeactivate(component:any,
                route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot,
                nextState: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
        return component.canExit ? component.canExit() : true;
    }
}
