import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { RequestService, LayoutUtilsService, LoaderService, StoreService } from '../../../shared/services';
import { CustomConfirmEntityDialogComponent } from '../modals/';
import { CustomSelectAutocompleteComponent } from '../../../shared/components/custom-select-autocomplete/custom-select-autocomplete.component';
import { environment } from '../../../../environments/environment';

interface DialogData {
	dataType: string;
	targetName: string;
	subDataType: string[];
	title: string;
	data: any;
	defaultFieldsObject: string[];
	fieldsObject: string[];
	dataCompetencies: any;
	modalSetting: any;
	canExport: boolean;
	filterSelected: any;
	presetFields: any;
	confirmData: any;
}

@Component({
	selector: 'filter-export-dialog-modal',
	templateUrl: './filter-export-dialog.component.html',
	styleUrls: ['./filter-export-dialog.component.scss']
})
export class ModalFilterExportDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public errorMessage: string = '';
	public loading: boolean = false;
	private customKeys: any = environment.customKeys; // used in view
	public selectedUser: any;
	public normalDisplay: string[] = ['string', 'phone', 'email', 'textarea', 'text'];
	public hiddenFields: string[] = ['phd'];
	public hasFormErrors: boolean = false;
	public metaFieldSetting = undefined;
	public filterChanged: boolean = false;
	public filterAdded: boolean = false;
	public dataType: string = 'filterquery';
	public dataTypeDisplay: string = 'Filter Query';
	public filterObject: any = { '$and': [] };
	public filterCompetenciesObject: any = [];
	public filterListView: any[] = [];
	public fieldsObject: any[] = [];
	public defaultFieldsObject: any[] = [];
	public selectedfield: string[] = [];
	public fieldObject: any = {};
	public fieldList: any[] = [];
	public fieldListOriginal: any[] = [];
	public selectedDataName: any = undefined;
	public selectedDatafilters: any = undefined;
	public competenciesDataSource: any[] = [];
	public competenciesDataSourceObject: any = {};
	public competenciesDisplayedColumns: string[] = ['benchmarkId', 'year1', 'year2', 'year3', 'year4_5', 'delete'];
	// public fieldObject: any = {};
	@ViewChild('customselectautocomplete') customselectautocomplete: CustomSelectAutocompleteComponent;
	constructor(private requestService: RequestService,
		private changeDetectorRefs: ChangeDetectorRef,
		public dialog: MatDialog,
		private layoutUtilsService: LayoutUtilsService, public store: StoreService,
		public dialogRef: MatDialogRef<ModalFilterExportDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData) {
		// console.log('ViewDialogData', data);
		if (this.data.data) {
			this.filterObject = JSON.parse(JSON.stringify(this.data.data));
		}
		if (this.data.fieldsObject) {
			this.fieldsObject = JSON.parse(JSON.stringify(this.data.fieldsObject));
		}
		if (this.data.defaultFieldsObject) {
			this.defaultFieldsObject = JSON.parse(JSON.stringify(this.data.defaultFieldsObject));
		}
		if (this.data.filterSelected) {
			this.selectedDataName = JSON.parse(JSON.stringify(this.data.filterSelected));
		}
		if (this.data.dataCompetencies) {
			this.filterCompetenciesObject = JSON.parse(JSON.stringify(this.data.dataCompetencies));
		}
	}

	ngOnInit() {
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;
					let selectedrole = this.store.get('selectedrole');
					let filter = { '$and': [] };
					filter['$and'].push({ 'roleuid': { '$eq': selectedrole.uid } });
					filter['$and'].push({ 'target': { '$eq': this.data.targetName } });
					this.selectedDatafilters = filter;
					this.loadData();
				}
			})
		);
	}
	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	public buildFilterListView() {
		this.filterListView = [];
		let filterListView = [];
		if (this.filterObject.hasOwnProperty('$and')) {
			for (let flt of this.filterObject['$and']) {
				let fltKeys = Object.keys(flt);
				let fltKey = fltKeys[0];
				let fltVal = flt[fltKey];
				let fld = undefined;
				if (fltKey === '$or') { // specific for program and phd
					let subflt = fltVal[0];
					let subfltKeys = Object.keys(subflt);
					fld = this.fieldObject[subfltKeys[0]];
					fltKey = subfltKeys[0];
				} else {
					fld = this.fieldObject[fltKey];
				}
				// console.log('addNewFilter', fld);
				if (!this.data.presetFields.includes(fltKey)) {
					let newFilter = { name: fltKey, include: false };
					if (fld.type === 'datetime' || fld.type === 'number') {
						if (fltVal.hasOwnProperty('$between')) {
							newFilter['value'] = fltVal['$between'][0];
							newFilter['secondvalue'] = fltVal['$between'][1];
						} else if (fltVal.hasOwnProperty('$gte')) {
							newFilter['value'] = fltVal['$gte'];
							newFilter['secondvalue'] = undefined;
						} else if (fltVal.hasOwnProperty('$lte')) {
							newFilter['value'] = undefined;
							newFilter['secondvalue'] = fltVal['$lte'];
						}
					} else if (fld.type === 'string' || fld.type === 'textarea') {
						if (fltVal.hasOwnProperty('$iLike')) {
							newFilter['value'] = fltVal['$iLike'].replace(/%/g, '');
						}
					} else if (fld.type === 'reference' && fld.reference.kind === 'multiple') {
						if (fltVal.hasOwnProperty('$contains')) {
							let filterVal = fltVal['$contains'];
							let valF = [];
							for (let vl of filterVal) {
								valF.push({ uid: vl, name: '' });
							}
							newFilter['value'] = valF;
						}
					} else if (fld.name !== 'program' && fld.type === 'reference' && fld.reference.kind === 'single') {
						if (fltVal.hasOwnProperty('$in')) {
							let valueIds = fltVal['$in'].map((values) => {
								return { name: '', uid: values };
							});
							newFilter['value'] = valueIds;
						}
					} else if (fld.name === 'program' && fld.type === 'reference' && fld.reference.kind === 'single') {
						if (fltVal.length > 0) {
							let valueIds = [];
							let hasClinical = false;
							let hasPhd = undefined;
							for (let valItem of fltVal) {
								valueIds.push({ name: '', uid: valItem['program'] });
								if (valItem['program'] === '0') {
									hasClinical = true;
									if (valItem.hasOwnProperty('phd')) {
										hasPhd = valItem['phd'];
									}
								}
							}
							newFilter['value'] = valueIds;
							if (hasClinical) {
								newFilter['phd'] = undefined;
								if (hasPhd) {
									newFilter['phd'] = hasPhd['$eq'];
								}
							}
						}
					} else if (fld.type === 'enum') {
						if (fltVal.hasOwnProperty('$in')) {
							newFilter['value'] = fltVal['$in'];
						}
					} else {
						if (fltVal.hasOwnProperty('$eq')) {
							newFilter['value'] = fltVal['$eq'];
						}
					}
					this.cleanSelectorsObject(fltKey);
					filterListView.push(newFilter);
				}
			}
		}
		if (this.data.subDataType.includes('competencies')) {
			for (let cmp of this.competenciesDataSource) {
				this.competenciesDataSourceObject[cmp.uid] = cmp;
			}
		}
		if (this.filterCompetenciesObject && this.filterCompetenciesObject.length > 0) {
			let newFilter = { name: 'competencies', value: [], include: false };

			for (let flt of this.filterCompetenciesObject) {
				let newFilterValue = { benchmarkId: { uid: '', name: '' }, year1: '', year2: '', year3: '', year4_5: '' }

				newFilterValue['benchmarkId']['uid'] = flt['benchmarkId'];
				if (flt.hasOwnProperty('year1')) {
					newFilterValue['year1'] = flt['year1'];
				}
				if (flt.hasOwnProperty('year2')) {
					newFilterValue['year2'] = flt['year2'];
				}
				if (flt.hasOwnProperty('year3')) {
					newFilterValue['year3'] = flt['year3'];
				}
				if (flt.hasOwnProperty('year4_5')) {
					newFilterValue['year4_5'] = flt['year4_5'];
				}
				if (newFilterValue.benchmarkId.uid !== '' && (newFilterValue.year1 !== '' || newFilterValue.year2 !== '' || newFilterValue.year3 !== '' || newFilterValue.year4_5 !== '')) {
					newFilter['value'].push(newFilterValue);
				}
			}
			this.cleanSelectorsObject('competencies');
			filterListView.push(newFilter);
		}
		// below order by the columns
		for (let fld of this.fieldsObject) {
			let currentItem = this.getItemListView(filterListView, fld);
			if (currentItem !== undefined) {
				let removedItem = filterListView.splice(currentItem, 1);
				let itemToInsert = removedItem[0];
				itemToInsert['include'] = true;
				this.filterListView.push(removedItem[0]);
			} else {
				this.getNewItemListView(fld);
			}
		}
		for (let flt of filterListView) {
			flt['include'] = false;
			this.filterListView.push(flt);
		}
	}
	public getItemListView(arrayLst, fldkey) {
		let idx = 0;
		for (let fld of arrayLst) {
			if (fld.name === fldkey || fld.name === '$' + fldkey + '$') {
				return idx;
			}
			idx++;
		}
		return undefined;
	}
	public getNewItemListView(fldkey) {
		let copiedItem = JSON.parse(JSON.stringify(this.fieldList));
		copiedItem = copiedItem.filter((fld) => {
			if (fld.inputName === fldkey || fld.inputName === '$' + fldkey + '$') {
				this.populateItemListView(fld);
				return false;
			} else {
				return true;
			}
		}
		);
		this.fieldList = JSON.parse(JSON.stringify(copiedItem));
	}
	public populateItemListView(fld) {
		let newFilter = { name: fld.inputName, include: true };
		if (fld.type === 'datetime') {
			newFilter['value'] = undefined;
			newFilter['secondvalue'] = undefined;
		} else if (fld.type === 'number') {
			newFilter['value'] = undefined;
			newFilter['secondvalue'] = undefined;
		} else if (fld.type === 'enum') {
			newFilter['value'] = [];
		} else if (fld.type === 'boolean') {
			newFilter['value'] = false;
		} else if (fld.type === 'reference') {
			newFilter['value'] = [];
			// if(fld.reference.kind === 'multiple'){
			// 	newFilter['value'] = [];
			// }else{
			// 	newFilter['value'] = '';
			// }
		} else if (fld.type === 'table') {
			newFilter['value'] = [{ benchmarkId: { uid: '', name: '' }, year1: '', year2: '', year3: '', year4_5: '' }];
		} else if (this.normalDisplay.includes(fld.type)) {
			newFilter['value'] = '';
		}
		this.filterListView.push(newFilter);
	}
	public loadData() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
				}
				if (data) {
					const copiedItem = JSON.parse(JSON.stringify(data.results.fields));
					this.fieldList = this.prepareFields(copiedItem);
					this.fieldListOriginal = JSON.parse(JSON.stringify(this.fieldList));
					this.metaFieldSetting = this.buildMetaSetting(data.results);
				} else {
					const copiedItem = JSON.parse(JSON.stringify(this.data.modalSetting.fields));
					this.fieldList = this.prepareFields(copiedItem);
					this.fieldListOriginal = JSON.parse(JSON.stringify(this.fieldList));
					this.metaFieldSetting = this.buildMetaSetting(this.data.modalSetting);
				}
				this.loading = false;
				this.loadDataCompetency();
			});
		}
	}
	public loadDataCompetency() {
		if (!this.loading) {
			this.loading = true;
			this.requestService.getDataL('benchmark', (data, error) => {
				if (error) {
					this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
				}
				if (data) {
					this.competenciesDataSource = Object.assign([], data.results);
				} else {
					this.competenciesDataSource = [];
				}
				this.buildFilterListView();
				this.loading = false;
			}, true);
		}
	}
	public prepareFields(fields, parent = undefined, parentName = undefined) {
		let fieldList: any[] = [];
		let fieldBuffer: any[] = [];
		for (let fld of fields) {
			// if(fld.visible && fld.searchable){
			if (fld.visible && fld.searchable && !(this.data.presetFields.includes(fld.name) && fld.parent === "") && fld.type !== 'image' && fld.type !== 'table' && !this.hiddenFields.includes(fld.name)) {
				if (parent && parentName) {
					fld['parent'] = parent; // after uday added parent
					fld['parentName'] = parentName; // after uday added parent
					fld['inputName'] = '$' + parent + '.' + fld['name'] + '$';
				} else {
					fld['inputName'] = fld['name'];
				}
				fld['disabled'] = false;
				fieldList.push(fld);
				this.fieldObject[fld.inputName] = fld;
			} else if (fld.type === 'object' && this.data.subDataType.includes(fld.name)) {
				let subData = this.prepareFields(fld.fields, fld.name, fld.displayName);
				// fieldList = fieldList.concat(subData);
				fieldBuffer.push({ name: fld.displayName, data: subData });
			} else if (fld.type === 'table' && this.data.subDataType.includes(fld.name)) {
				fld['inputName'] = fld['name'];
				this.fieldObject[fld.name] = fld;

				this.fieldObject[fld.name]['fields'].push({ type: 'action', name: 'delete', displayName: 'Delete' });
				fieldList.push(fld);
				let subData = this.prepareFields(fld.fields, fld.name, fld.displayName);
				// fieldList = fieldList.concat(subData);
			}
		}
		for (let bf of fieldBuffer) {
			fieldList.push({ displayName: bf.name, inputName: '', disabled: true })
			fieldList = fieldList.concat(bf.data);
		}
		// console.log('fieldObject', this.fieldObject);
		// console.log('fieldList', fieldList);
		// console.log('fieldObject', this.fieldObject);
		return fieldList;
	}
	public selectField(val) {
		this.selectedfield = val;
		console.log('selectField', val);
	}
	public addNewFilter() {
		if (!this.checkSelected()) {
			this.filterChanged = true;
			let copiedItem = JSON.parse(JSON.stringify(this.fieldList));
			copiedItem = copiedItem.filter((fld) => {
				if (this.selectedfield.includes(fld.inputName)) {
					this.populateItemListView(fld);
					return false;
				} else {
					return true;
				}
			}
			);
			this.fieldList = JSON.parse(JSON.stringify(copiedItem));
			this.selectedfield = [];
		} else {
			this.layoutUtilsService.showNotification('Field already Added', 'Dismiss');
		}
	}
	public removeFilter(idx) {
		let currentSelect = this.filterListView[idx];
		let idx1 = 0;
		for (let fld of this.fieldListOriginal) {
			if (currentSelect.name === fld.inputName) {
				this.fieldList.splice(idx1, 0, fld);
				this.filterChanged = true;
				break;
			}
			idx1++;
		}
		this.filterListView.splice(idx, 1);
	}
	public checkSelected() {
		for (let fld of this.filterListView) {
			if (this.selectedfield.includes(fld.name)) {
				return true;
			}
		}
		return false;
	}
	public setAttributeDummy(index, id, val) {
		this.filterChanged = true;
	}
	public setAttribute(index, id, val) {
		this.filterChanged = true;
		this.filterListView[index][id] = val;
	}
	public setDateAttribute(index, id, val) {
		this.filterChanged = true;
		// console.log('setDateAttribute', id, val.toISOString());
		try {
			this.filterListView[index][id] = val.toISOString();
		} catch (e) {
			// error
		}
	}
	public setReferenceAttribute(index, id, val) {
		this.filterChanged = true;
		if (this.filterListView[index].name === 'program') {
			this.filterListView[index][id] = val;
			if (val === '0') {
				this.filterListView[index]['phd'] = undefined;
			} else {
				delete this.filterListView[index]['phd'];
			}
		} else {
			this.filterListView[index][id] = val;
		}
	}
	public setMultipleReferenceAttribute(index, id, val) {
		this.filterChanged = true;
		this.filterListView[index][id] = val;

		if (this.filterListView[index].name === 'program') {
			let hasClinical = false;
			for (let flt of val) {
				if (flt.uid === '0') {
					hasClinical = true;
					break;
				}
			}
			if (hasClinical) {
				this.filterListView[index]['phd'] = undefined;
			} else {
				delete this.filterListView[index]['phd'];
			}
		}
	}
	closeModal(data): void {
		if (data.filter) {
			this.buildFilter();
			let selectedData = undefined;
			if (this.selectedDataName && this.selectedDataName.hasOwnProperty('uid') && this.selectedDataName.uid) {
				selectedData = this.selectedDataName;
			}
			let fieldsObject = this.fieldsObject;
			let filterSelected = this.filterChanged ? undefined : selectedData;
			this.dialogRef.close({ fieldsObject: fieldsObject, filter: this.filterObject, filterCompetencies: this.filterCompetenciesObject, filterSelected: filterSelected });
		} else {
			this.dialogRef.close(undefined);
		}
	}

	cleanSelectorsObject(fltKey) {
		let idx = 0;
		for (let fld of this.fieldList) {
			if (fld.inputName === fltKey) {
				this.fieldList.splice(idx, 1);
				break;
			}
			idx++;
		}
	}
	cleanFilterObject(filter = undefined) {
		let copiedItem = JSON.parse(JSON.stringify(this.filterObject));
		if (filter && filter.hasOwnProperty('$and')) {
			this.filterObject['$and'] = filter['$and'];
		} else {
			this.filterObject['$and'] = [];
		}
		if (copiedItem.hasOwnProperty('$and')) {
			let idx = 0;
			for (let flt of copiedItem['$and']) {
				let fltKey = Object.keys(flt)[0];
				if (this.data.presetFields.includes(fltKey)) {
					this.filterObject['$and'].push(flt);
				}
				idx++;
			}
		}
	}
	cleanFilterCompetenciesObject(filter = undefined) {
		if (filter) {
			this.filterCompetenciesObject = filter;
		} else {
			this.filterCompetenciesObject = [];
		}
	}
	getCleanFilterObject(filter) {
		let copiedItem = {};
		if (filter) {
			copiedItem = JSON.parse(JSON.stringify(filter));
			copiedItem['$and'] = [];
			if (filter.hasOwnProperty('$and')) {
				let idx = 0;
				for (let flt of filter['$and']) {
					let fltKey = Object.keys(flt)[0];
					if (!this.data.presetFields.includes(fltKey)) {
						copiedItem['$and'].push(flt);
					}
					idx++;
				}
			}
		}
		return copiedItem;
	}
	buildFilter() {
		this.filterAdded = false;
		this.cleanFilterObject();
		this.fieldsObject = [];
		for (let fld of this.filterListView) {
			let newObject = {};
			let cleanName = fld.name.replace(/\$/g, '');
			if (this.fieldObject[fld.name].type === 'datetime' || this.fieldObject[fld.name].type === 'number') {
				if (fld.value || fld.secondvalue) {
					if (fld.value && fld.secondvalue) {
						newObject[fld.name] = { "$between": [fld.value, fld.secondvalue] };
					} else if (fld.value && !fld.secondvalue) {
						newObject[fld.name] = { "$gte": fld.value };
					} else if (!fld.value && fld.secondvalue) {
						newObject[fld.name] = { "$lte": fld.secondvalue };
					}
					this.filterObject['$and'].push(newObject);
					this.filterAdded = true;
				}
				if (fld.include) {
					this.fieldsObject.push(cleanName);
				}
			} else if (this.fieldObject[fld.name].type === 'string' || this.fieldObject[fld.name].type === 'textarea') {
				if (fld.value) {
					newObject[fld.name] = { "$iLike": '%' + fld.value.trim() + '%' };
					this.filterObject['$and'].push(newObject);
					this.filterAdded = true;
				}
				if (fld.include) {
					this.fieldsObject.push(cleanName);
				}
			} else if (this.fieldObject[fld.name].type === 'table') {
				// newFilter['value'] = [{benchmarkId: {uid: '', name: ''} , year1: '', year2: '', year3: '', year4_5: ''}];
				this.filterCompetenciesObject = [];
				if (fld.value && fld.value.length > 0) {
					for (let dt of fld.value) {
						if (dt.benchmarkId.uid !== '' && (dt.year1 !== '' || dt.year2 !== '' || dt.year3 !== '' || dt.year4_5 !== '')) {
							let newFilter = { benchmarkId: '' };
							if (dt.benchmarkId.uid !== '')
								newFilter.benchmarkId = dt.benchmarkId.uid;
							if (dt.year1 !== '')
								newFilter['year1'] = dt.year1;
							if (dt.year2 !== '')
								newFilter['year2'] = dt.year2;
							if (dt.year3 !== '')
								newFilter['year3'] = dt.year3;
							if (dt.year4_5 !== '')
								newFilter['year4_5'] = dt.year4_5;
							this.filterCompetenciesObject.push(newFilter);
							this.filterAdded = true;
						}
					}
				}
			} else if (this.fieldObject[fld.name].type === 'reference' && this.fieldObject[fld.name].reference.kind === 'multiple') {
				if (fld.value) {
					let filterVal = [];
					for (let vl of fld.value) {
						filterVal.push(vl.uid);
					}
					if (filterVal.length > 0) {
						newObject[fld.name] = { "$contains": filterVal };
						this.filterObject['$and'].push(newObject);
						this.filterAdded = true;
					}
				}
				if (fld.include) {
					this.fieldsObject.push(cleanName);
				}
			} else if (fld.name !== 'program' && this.fieldObject[fld.name].type === 'reference' && this.fieldObject[fld.name].reference.kind === 'single') {
				if (fld.value && fld.value.length > 0) {
					let valueIds = fld.value.map((values) => {
						return values.uid
					});
					newObject[fld.name] = { "$in": valueIds };
					this.filterObject['$and'].push(newObject);
					this.filterAdded = true;
				}
				if (fld.include) {
					this.fieldsObject.push(cleanName);
				}
			} else if (fld.name === 'program' && this.fieldObject[fld.name].type === 'reference' && this.fieldObject[fld.name].reference.kind === 'single') {
				if (fld.value && fld.value.length > 0) {
					let valueIds = fld.value.map((values) => {
						return values.uid
					});

					newObject['$or'] = [];
					for (let valued of valueIds) {
						let objData = { "program": valued };
						if (valued === '0' && fld.hasOwnProperty('phd')) {
							if (fld.phd !== undefined) {
								objData['phd'] = { "$eq": fld.phd };
							}
						}
						newObject['$or'].push(objData);
					}
					if (newObject['$or'].length > 0) {
						this.filterObject['$and'].push(newObject);
						this.filterAdded = true;
					}
				}
				if (fld.include) {
					this.fieldsObject.push(cleanName);
				}
			} else if (this.fieldObject[fld.name].type === 'enum') {
				if (fld.value && fld.value.length > 0) {
					newObject[fld.name] = { "$in": fld.value };
					this.filterObject['$and'].push(newObject);
					this.filterAdded = true;
				}
				if (fld.include) {
					this.fieldsObject.push(cleanName);
				}
			} else {
				if (fld.value) {
					newObject[fld.name] = { "$eq": fld.value };
					this.filterObject['$and'].push(newObject);
					this.filterAdded = true;
				}
				if (fld.include) {
					this.fieldsObject.push(cleanName);
				}
			}
		}
	}
	buildMetaSetting(data, parent = undefined) {
		let dataObject = {};
		let tabObject = [];
		for (let col of data.fields) {
			if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
				if (parent) {
					col['inputName'] = parent + col['name'];
				}
				dataObject[col.name] = col;
			} else if (col.type === 'object') {
				dataObject[col.name] = this.buildMetaSetting(col);
				tabObject.push({ name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name) });
			} else if (col.type === 'table') {
				dataObject[col.name] = this.buildMetaSetting(col);
			}
		}
		return dataObject;
	}
	saveFilter(dataObject) {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			let dataUid = dataObject.hasOwnProperty('uid') ? dataObject.uid : undefined;
			this.requestService.saveData(this.dataType, dataObject, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
				}
				if (data) {
					this.selectedDataName = { uid: data.results.uid, text: data.results.name };
					this.filterChanged = false;
					if (dataUid) {
						this.layoutUtilsService.showNotification('Filter Edited Successfully.', 'Dismiss');
					} else {
						this.layoutUtilsService.showNotification('Filter created Successfully.', 'Dismiss');
					}
				}
				this.loading = false;
			});
		}
	}
	createFilter(): void {
		this.buildFilter();
		let filterSelected = this.filterChanged ? undefined : this.selectedDataName;
		if (this.filterAdded) {
			if (this.selectedDataName && this.selectedDataName.text !== '') {
				let cleanObj = this.getCleanFilterObject(this.filterObject);
				let cleanObjCompetencies = this.getCleanFilterObject(this.filterCompetenciesObject);
				let selectedrole = this.store.get('selectedrole');
				let dataObject = { name: this.selectedDataName.text, roleuid: selectedrole.uid, filter: JSON.stringify(cleanObj), competencies: JSON.stringify(cleanObjCompetencies), target: this.data.targetName, fields: this.fieldsObject };
				if (this.selectedDataName.uid) {
					dataObject['uid'] = this.selectedDataName.uid;
					this.continuereateFilter(dataObject);
				} else {
					this.saveFilter(dataObject);
				}
			} else {
				this.layoutUtilsService.showNotification('Fill or select filter name', 'Dismiss');
			}
		} else {
			this.layoutUtilsService.showNotification('Add a field to create a new filter', 'Dismiss');
		}
	}
	continuereateFilter(dataObject): void {
		const dialogRef = this.dialog.open(CustomConfirmEntityDialogComponent, {
			width: '600px',
			autoFocus: false,
			data: {
				title: 'Save Filter',
				description: 'Do you want to create a new filter or edit the selected one?',
				cancelbtn: 'Cancel',
				confirmbtn: 'Create',
				confirmbtnData: 'new',
				confirmbtnSecond: 'Edit Current Filter',
				confirmbtnSecondData: 'edit'
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				if (result === 'new') {
					this.customselectautocomplete.inputName.nativeElement.focus();
				} else {
					this.saveFilter(dataObject);
				}
			}
		});
	}
	public setSelectedDataName(val) {
		this.selectedDataName = val;
		this.filterChanged = false;
		if (this.selectedDataName.uid) {
			if (!this.loading) {
				this.loading = true;
				this.errorMessage = '';
				this.requestService.getSingleData(this.dataType, this.selectedDataName.uid, (data, error) => {
					if (error) {
						this.errorMessage = error;
						this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
					}
					if (data) {
						// console.log('data', data.results);
						if (data.results.filter) {
							this.cleanFilterObject(JSON.parse(data.results.filter));
							this.cleanFilterCompetenciesObject(JSON.parse(data.results.competencies));
							this.fieldList = JSON.parse(JSON.stringify(this.fieldListOriginal));
						}
						if (data.results.fields) {
							if (data.results.fields.length > 0) {
								this.fieldsObject = JSON.parse(JSON.stringify(data.results.fields));
							}
						}
						this.buildFilterListView();
					}
					this.loading = false;
				});
			}
		}
	}

	public setTableAttribute(parentId, index, indexTable, id, val) {
		this.filterChanged = true;
		let filterData = JSON.parse(JSON.stringify(this.filterListView));
		filterData[index]['value'][indexTable][id] = val;
		this.filterListView = filterData;
		this.changeDetectorRefs.detectChanges();
		// this.tableData[parentId][index][id] = val;
	}
	public setTableReferenceAttribute(parentId, index, indexTable, parent, id, val) {
		this.filterChanged = true;
		let filterData = JSON.parse(JSON.stringify(this.filterListView));
		filterData[index]['value'][indexTable][parent][id] = val;
		this.filterListView = filterData;
		this.changeDetectorRefs.detectChanges();
	}
	public removeCompetenciesFilter(parentId, index, indexTable, parent) {
		this.filterChanged = true;
		let filterData = JSON.parse(JSON.stringify(this.filterListView));
		filterData[index]['value'].splice(indexTable, 1);;
		this.filterListView = filterData;
		this.changeDetectorRefs.detectChanges();
	}
	public addMoreCompetency(index) {
		let filterData = JSON.parse(JSON.stringify(this.filterListView));
		this.filterChanged = true;
		filterData[index]['value'].push({ benchmarkId: { uid: '', name: '' }, year1: '', year2: '', year3: '', year4_5: '' });
		this.filterListView = filterData;
		this.changeDetectorRefs.detectChanges();
	}
}
