import { Component, Inject, OnInit, ChangeDetectorRef} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { EmbedVideoService } from 'ngx-embed-video';

export interface ViewVideoDialogData {
	dataType: string;
	title: string;
	data: any;
	modalSetting: any;
	confirmData: any;
}

@Component({
	selector: 'app-video-view-dialog-modal',
	templateUrl: './custom-video-view-dialog.component.html',
  styleUrls: ['./custom-video-view-dialog.component.scss']
})
export class ModalVideoViewDialogComponent implements OnInit {
	public errorMessage: string = '';
  public loading: boolean = false;
  public hasFormErrors: boolean = false;
	public iframe_html: any;
	constructor(private embedService: EmbedVideoService, private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalVideoViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewVideoDialogData) {
			console.log('ViewDialogData', data);
			this.iframe_html = undefined;
	}

	ngOnInit() {
			this.loadData();
	}
	public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getSingleData(this.data.dataType, this.data.data['uid'], (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        if (data) {
          this.data.data = data.results;
					this.iframe_html = this.embedService.embed(this.data.data['fileLink'], {
					  query: { portrait: 0, color: '333' },
					  attr: { width: '100%', height: 300 }
					});
					// this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.data.data['fileLink']);
        }
        this.loading = false;
      });
    }
  }
  closeModal(data): void {
    this.dialogRef.close(data);
  }
}
