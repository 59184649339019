import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RequestService, LayoutUtilsService, LoaderService, PageScopeService, StoreService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { environment } from '../../../../environments/environment';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { ModalPasswordDialogComponent } from '../../../shared/components/custom-password-dialog/custom-password-dialog.component';
import { ConfirmEntityDialogComponent } from '../modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { ModalMultipleAttachmentsDialogComponent } from '../custom-multiple-attachments-dialog/custom-multiple-attachments-dialog.component';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

export interface UserViewDialogData {
	dataType: string;
	title: string;
	data: any;
	profile: boolean;
	modalSetting: any;
	confirmData: any;
}

@Component({
	selector: 'app-user-view-dialog-modal',
	templateUrl: './custom-user-view-dialog.component.html',
	styleUrls: ['./custom-user-view-dialog.component.scss']
})
export class ModalUserViewDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public selectedUser: any;
	public selectedrole: any = undefined;
	public customKeys: any = environment.customKeys; // used in view
	public customDisplayKeys: any = environment.customDisplayKeys; // used in view
	public errorMessage: string = '';
	public scopes: string[] = [];
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public canSeeAttachments: boolean = false;
	public canEditAttachments: boolean = false;
	public attachmentsCount: any = {};
	public isStudent: boolean = false;
	public pictureLink: string = 'assets/images/profile.jpg';
	public fieldsDetails: any = undefined;
	public permission: any[] = [environment.customKeys.roleAdmin];
	public metaFieldTabsSetting = [];
	public oldRolesList: any[] = [];
	public oldRoles: string = undefined;
	public tableDataTemp: any = undefined;
	public normalDisplay: string[] = ['string', 'integer', 'phone', 'number', 'email', 'textarea', 'text'];
	public competenciesDataSource: any[] = [];
	public competenciesDisplayedColumns: string[] = ['benchmarkId', 'year1', 'year2', 'year3', 'year4_5'];
	public competencylegaciesDisplayedColumns: string[] = ['Rater', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'];
	public competencylegaciesDisplayedColumnsNoRater: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'];
	public competencyLegacyData = { ca: [], fr: [], ir: [], pr: [] };
	public transcriptDisplayedColumns: string[] = ['year1', 'year2', 'year3', 'year4_5'];
	public transcriptData: any[] = [{
		year1: {
			fileLink: undefined,
			name: "",
			year: "year1"
		}, year2: {
			fileLink: undefined,
			name: "",
			year: "year2"
		}, year3: {
			fileLink: undefined,
			name: "",
			year: "year3"
		}, year4_5: {
			fileLink: undefined,
			name: "",
			year: "year4_5"
		}
	}];
	public evaluationLetterDisplayedColumns: string[] = ['title', 'year1', 'year2', 'year3', 'year4_5'];
	public evaluationLetterData: any[] = [
		{
			title: {
				name: 'Evaluation Letter'
			},
			year1: {
				name: 'First Year', fileLink: undefined, year: "year1"
			}, year2: {
				name: '2nd Year', fileLink: undefined, year: "year2"
			}, year3: {
				name: '3rd Year', fileLink: undefined, year: "year3"
			}, year4_5: {
				name: '4-5th Year', fileLink: undefined, year: "year4_5"
			}
		}
	];

	constructor(
		public dialog: MatDialog,
		private requestService: RequestService,
		private layoutUtilsService: LayoutUtilsService,
		private pageScopeService: PageScopeService, public store: StoreService, private permissionsService: NgxPermissionsService,
		public dialogRef: MatDialogRef<ModalUserViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: UserViewDialogData) {
		console.log('UserViewDialogData', data);
	}

	ngOnInit() {
		this.subscriptions.push(
			this.pageScopeService.menuSelectedScope.subscribe((scopes) => {
				this.scopes = scopes
			}
			)
		);
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;
					this.selectedrole = this.store.get('selectedrole');
					this.canSeeAttachments = this.canSeeAttachment();
					this.canEditAttachments = this.canEditAttachment();
					this.buildSetting();
				}
			})
		);
	}
	public hasUidRole(uid) {
		if (this.data.confirmData['roles']) {
			for (let rl of this.data.confirmData['roles']) {
				if (rl.uid === uid) {
					return true;
				}
			}
		}
		return false;
	}
	public hasTargetRole(targetfield) {
		if (this.data.confirmData['roles']) {
			for (let rl of this.data.confirmData['roles']) {
				if (rl.targetfield === targetfield) {
					return true;
				}
			}
		}
		return false;
	}
	private buildSetting() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
				}
				this.loading = false;
				if (data) {
					this.data.modalSetting = data.results;
					this.fieldsDetails = this.buildMetaSetting(data.results);
					// console.log('this.fieldsDetails', this.fieldsDetails);
					if (this.data.data.hasOwnProperty('uid')) {
						this.loadData();
					}
				} else {
					this.layoutUtilsService.showNotification('Something is Wrong', 'Dismiss');
				}
			});
		}
	}
	public buildInternshipData() {
		if (this.data.confirmData['internship']) {
			if (!this.data.confirmData['internshipobj']) {
				this.data.confirmData['internshipobj'] = {};
			}
		} else {
			this.data.confirmData['internshipobj'] = null;
		}
	}
	public buildPracticumData() {
		if (this.data.confirmData['practicum']) {
			if (!this.data.confirmData['practicumobj']) {
				this.data.confirmData['practicumobj'] = {};
			}
		} else {
			this.data.confirmData['practicumobj'] = null;
		}
	}
	public buildTranscriptData() {
		if (this.data.confirmData['transcript']) {
			for (let itm of this.data.confirmData['transcript']) {
				this.transcriptData[0][itm.year] = itm;
			}
		}
	}
	public buildEvaluationLetterData() {
		if (this.data.confirmData['evaluationletter']) {
			for (let itm of this.data.confirmData['evaluationletter']) {
				this.evaluationLetterData[0][itm.year] = itm;
			}
		}
	}
	public loadData() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.getSingleData(this.data.dataType, this.data.data['uid'], (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
				}
				if (data) {
					this.tableDataTemp = Object.assign({}, data.results);
					this.data.confirmData = Object.assign({}, data.results);
					// console.log('this.data.confirmData ', this.data.confirmData);
					if (data.results['pictureLink']) {
						this.pictureLink = data.results['pictureLink']['fileLink'];
					}
					this.buildattachmentsCount();
					this.buildTabsMetaSetting();
					this.buildInternshipData();
					this.buildPracticumData();
					this.buildTranscriptData();
					this.buildEvaluationLetterData();
					this.buildCompetenciesData();
					this.buildCompetenciesLegacyData();
					this.loadOldRolesList();
				}
				this.loading = false;
			});
		}
	}
	buildCompetenciesLegacyData() {
		if (this.data.confirmData['competencylegacies']) {
			this.competencyLegacyData = { ca: [], fr: [], ir: [], pr: [] };
			for (let cmp of this.data.confirmData['competencylegacies']) {
				cmp['time'] = cmp.ts.replace(' 00:00:00', '');
				if (cmp.SubTab === '1') {
					this.competencyLegacyData.ca.push(cmp);
				} else if (cmp.SubTab === '2') {
					this.competencyLegacyData.fr.push(cmp);
				} else if (cmp.SubTab === '3') {
					this.competencyLegacyData.ir.push(cmp);
				} else if (cmp.SubTab === '4') {
					this.competencyLegacyData.pr.push(cmp);
				}
			}
		} else {
			this.competencyLegacyData = { ca: [], fr: [], ir: [], pr: [] };
		}

	}
	private returnObjectOfArray(uid) {
		for (let cmp of this.tableDataTemp['competencies']) {
			if (cmp.benchmarkId.uid === uid) {
				return cmp;
			}
		}
		return undefined;
	}
	public buildCompetenciesData() {
		if ((this.hasUidRole(this.customKeys.roleSchoolStudent) || this.data.confirmData.hasOwnProperty('schoolstudent')) && this.data.confirmData['program']['uid'] !== '') {
			this.requestService.getDataL('benchmark', (data, error) => {
				if (error) {
					this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
				}
				if (data) {
					this.competenciesDataSource = Object.assign([], data.results);
					let competencyObj = [];
					for (let cmp of this.competenciesDataSource) {
						if (cmp.program.includes(this.data.confirmData['program']['uid'])) {
							let itemObj = { benchmarkId: cmp, year1: '', year2: '', year3: '', year4_5: '' };

							if (!this.tableDataTemp.hasOwnProperty('competencies') || (this.tableDataTemp.hasOwnProperty('competencies') && this.tableDataTemp['competencies'].length === 0)) {
								competencyObj.push({ benchmarkId: cmp, year1: '', year2: '', year3: '', year4_5: '' });
							} else {
								let itemInObj = this.returnObjectOfArray(cmp.uid);
								if (itemInObj) {
									itemInObj['benchmarkId'] = cmp;
									competencyObj.push(itemInObj);
								} else {
									competencyObj.push(itemObj);
								}
							}
						}
					}
					this.data.confirmData['competencies'] = JSON.parse(JSON.stringify(competencyObj));
				} else {
					this.competenciesDataSource = [];
				}
			}, true);
		} else {
			this.data.confirmData['competencies'] = [];
		}
	}
	convertArrayToObject(arr: any[]) {
		let obj = {};
		for (let a of arr) {
			obj[a.uid] = a;
		}
		return obj;
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
	buildMetaSetting(data, parent = undefined) {
		let dataObject = {};
		let tabObject = [];
		for (let col of data.fields) {
			if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
				if (parent) {
					col['inputName'] = parent + col['name'];
				}
				dataObject[col.name] = col;
			} else if (col.type === 'object') {
				dataObject[col.name] = this.buildMetaSetting(col);
				tabObject.push({ name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name) });
			} else if (col.type === 'table') {
				dataObject[col.name] = this.buildMetaSetting(col);
			}
		}
		this.metaFieldTabsSetting = tabObject;
		return dataObject;
	}
	changePassword() {
		const dialogRef = this.dialog.open(ModalPasswordDialogComponent, {
			width: '300px',
			data: {
				title: 'Reset Password'
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				// console.log('result', result);
			}
		});
	}
	resetPassword() {
		const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
			width: '300px',
			data: {
				title: 'Reset Password',
				description: 'Are you sure you want to reset the password for this user? An email will be sent to the user with a new autogenerated password.',
				cancelbtn: 'Cancel',
				confirmbtn: 'Reset'
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				// console.log('result', result);
				if (result) {
					this.confirmResetPassword();
				}
			}
		});
	}
	confirmResetPassword() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.resetUserPassword(this.data.data['uid'], (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
				}
				if (data) {
					this.layoutUtilsService.showNotification('Password has been reset successfully.', 'Dismiss');
				}
				this.loading = false;
			});
		}
	}
	public buildTabsMetaSetting() {
		if (this.data.confirmData) {
			for (let tab of this.metaFieldTabsSetting) {
				if (!this.tableDataTemp.hasOwnProperty(tab.name)) {
					if (this.data.confirmData.hasOwnProperty(tab.name)) {
						delete this.data.confirmData[tab.name];
					}
				}
				if (this.data.confirmData.hasOwnProperty(tab.name) || this.hasTargetRole(tab.name)) {
					if (!this.data.confirmData.hasOwnProperty(tab.name)) {
						this.data.confirmData[tab.name] = {}
					}
				}
			}
			if (!this.data.confirmData.hasOwnProperty('schoolstudent') && !this.hasTargetRole('schoolstudent') && this.hasTargetRole('alumni')) {
				let roleObj = this.initializeUserRoleData('schoolstudent');
				this.data.confirmData['schoolstudent'] = roleObj;
			}
		}
	}
	public initializeUserRoleData(fld) {
		let newObj = {};
		for (let col of this.data.modalSetting.fields) {
			if ((col.editable || !col.generated) && col.type !== 'action' && col.visible) {
				if (col.type === 'object' && col.name === fld) {
					for (let subcol of col.fields) {
						if ((subcol.editable || !subcol.generated) && subcol.type !== 'action' && subcol.visible) {
							if (subcol.type === 'reference') {
								newObj[subcol.name] = { name: '', uid: '' };
							} else if (subcol.type === 'object') {
								// do nothing
							} else if (subcol.type === 'table') {
								newObj[subcol.name] = [];
							} else if (col.type === 'boolean') {
								newObj[subcol.name] = false;
							} else {
								newObj[subcol.name] = '';
							}
						}
					}
				}
			}
		}
		return newObj;
	}
	public viewTranscript(transcript: any) {
		window.open(transcript.fileLink, '_blank');
	}
	public viewEvaluationLetter(attachment: any) {
		window.open(attachment.fileLink, '_blank');
	}
	public loadOldRolesList() {
		this.loading = true;
		this.errorMessage = '';
		this.requestService.getDataL('role', (data, error) => {
			if (error) {
				this.errorMessage = error;
			}
			if (data) {
				this.oldRolesList = data.results;
				this.loadOldRoles();
			} else {
				this.oldRolesList = [];
			}
		}, true);
	}
	public loadOldRoles() {
		let arrList: string[] = [];
		for (let rl of this.oldRolesList) {
			if (this.data.confirmData.hasOwnProperty(rl.targetfield) && !this.hasUidRole(rl.uid)) {
				arrList.push(rl.name);
				if (this.customKeys.roleSchoolStudent === rl.uid) {
					this.isStudent = true;
				}
			}
		}
		this.oldRoles = arrList.join(', ');
	}
	public openAttachments(e, type, field1, field2) {
		if (e) {
			e.stopImmediatePropagation();
			e.preventDefault();
		}
		let title = 'Upload documents for ' + this.fieldsDetails[field1][field2].displayName;
		if (type === 'topic') {
			title = this.fieldsDetails[field1][field2].displayName;
		}
		const dialogRef = this.dialog.open(ModalMultipleAttachmentsDialogComponent, {
			width: '1200px',
			data: {
				title: title,
				data: [],
				canEdit: false,
				userId: this.data.data['uid'],
				type: type,
				field1: field1,
				field2: field2
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				// console.log('result', result);
				// this.loadData();
			}
		});
	}
	canSeeAttachment() {
		if ([environment.customKeys.roleAdmin, environment.customKeys.roleFaculty, environment.customKeys.roleSupervisor].includes(this.selectedrole.uid) || this.selectedUser.uid === this.data.data['uid']) {
			return true;
		}
		return false;
	}
	canEditAttachment() {
		if ([environment.customKeys.roleAdmin].includes(this.selectedrole.uid)) {
			return true;
		}
		return false;
	}
	public buildattachmentsCount() {
		let attachmentsCount = {};
		if (this.data.confirmData && this.data.confirmData.docs) {
			for (let itm of this.data.confirmData.docs) {
				if (!attachmentsCount.hasOwnProperty(itm.field)) {
					attachmentsCount[itm.field] = 0;
				}
				attachmentsCount[itm.field]++;
			}
		}
		this.attachmentsCount = attachmentsCount;
	}
}
