import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { RequestService, LayoutUtilsService, LoaderService, StoreService } from '../../../shared/services';
import { CustomMetadataTableComponent } from '../../../shared/components/custom-metadata-table/custom-metadata-table.component';

interface DialogData {
	dataType: string;
	dataTypeDisplay: string;
	fieldsToHide: string;
	selectedFilter: string;
	filterObject: any;
	filterCompetenciesObject: any;
	subDataType: string[];
	title: string;
	data: any;
	selectedDataId: any;
	modalSetting: any;
	confirmData: any;
}

@Component({
	selector: 'preview-dialog-modal',
	templateUrl: './preview-dialog.component.html',
  styleUrls: ['./preview-dialog.component.scss']
})
export class ModalPreviewDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public errorMessage: string = '';
  public loading: boolean = false;
	public selectedUser: any;
	public normalDisplay: string[] = ['string', 'phone', 'email', 'textarea', 'text'];
  public hasFormErrors: boolean = false;
  public metaFieldSetting = undefined;
	public tableSetting: any = undefined;
  public fieldList: any[] = [];
  public fieldListOriginal: any[]= [];
  public fieldObject: any = {};

  // public fieldObject: any = {};
	public fieldsToHide: string[] = ['phd'];

	@ViewChild('customMetadataTable') custom_metadata_table: CustomMetadataTableComponent;
	constructor(private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService, public store: StoreService,
		public dialogRef: MatDialogRef<ModalPreviewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData) {
			console.log('data', data);
	}

	ngOnInit() {
			this.subscriptions.push(
        this.requestService.currentUserSubject.subscribe((data) => {
          if (data) {
            this.selectedUser = data;
						this.loadData();
          }
        })
      );
	}
	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	public loadData() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.getMetaData(this.data.dataType, this.data.data.fieldsObject, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
				}
				if (data) {
					const copiedItem = JSON.parse(JSON.stringify( data.results));
					this.tableSetting = this.getSetting(copiedItem, this.data.data.filter, this.data.data.filterCompetencies);
				} else {
					const copiedItem = JSON.parse(JSON.stringify(this.data.modalSetting));
					this.tableSetting = this.getSetting(copiedItem, this.data.data.filter, this.data.data.filterCompetencies);
				}
				this.loading = false;
			});
		}
  }
  closeModal(data): void {
		if(data.export){
			this.dialogRef.close({fieldsObject: this.data.data.fieldsObject, filter: this.getCustomFilter(this.data.data.filter), filterCompetencies: this.getCustomFilterCompetencies(this.data.data.filterCompetencies)});
		}else{
			this.dialogRef.close(undefined);
		}
  }
	private getSetting(data, filters, filterCompetencies){
		let tableSetting = data;
		tableSetting['defaultCount'] = 10;
		tableSetting['target'] = 'parent';
		tableSetting['filters'] = this.getCustomFilter(filters);
		tableSetting['competencies'] = this.getCustomFilterCompetencies(filterCompetencies);
		return tableSetting;
	}
	private getCustomFilterCompetencies(filterCompetencies){
		if(filterCompetencies){
			return JSON.parse(JSON.stringify(filterCompetencies));
		}else{
			return this.data.filterCompetenciesObject;
		}
	}
	private getCustomFilter(filters){
		if(filters){
			return JSON.parse(JSON.stringify(filters));
		}else{
			return this.data.filterObject;
		}
	}
}
