import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, ElementRef} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
// import { ModalDialogComponent } from '../custom-dialog/custom-dialog.component';

import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { UsableUser } from '../../../shared/services/models/usable-user';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent implements OnInit {
	private subscriptions: Subscription[] = [];
  public errorMessage: string = '';
  normalDisplay: string[] = ['string', 'phone', 'number', 'email', 'textarea', 'text'];
  tableData: Array<any> = [];
  tableDataTotal: number = 0;

  selection = new SelectionModel<any>(true, []);
  dataSource: any;
  // displayedColumns = ['select', 'uid', 'name', 'email', 'actions'];
  displayedColumns = [];
  /* pagination Info */
  pageSize = 10;
  pageNumber = 1;

  orderDir = 'asc';
  orderBy = 'name'; // uid
  @Input() canMultiSelect: boolean = false;
  @Input() canNew: boolean = false;
  @Input() canSearch: boolean = true;
  @Input() canRefresh: boolean = true;
  @Input() title: string = '';
  @Input() dataType: string;
  @Input() dataTypeDisplay: string = 'Data';
  public _loading: boolean = false;
  @Input()
  set loading(loading: boolean) {
    this._loading = loading;
    this.dataLoading.next(loading);
  }
  get loading(): boolean {
    return this._loading;
  }
  public dataLoading: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean>(false);
  public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public _tableSetting: any = { columns: []};
  @Input()
  set tableSetting(tableSetting: any) {
    this._tableSetting = tableSetting;
    // console.log('tableSetting', tableSetting);
    if(tableSetting){
      if (tableSetting.columns.length > 0) {
        if (this.canMultiSelect) {
          this.displayedColumns.push('select'); // remove this and add at config level
        }
        for (let col of tableSetting.columns) {
          if (!col.hidden) {
            this.displayedColumns.push(col.id);
          }
        }
      }
    }
  }
  get tableSetting(): any {
    return this._tableSetting;
  }
  @Output() onClickBtn = new EventEmitter<any>();
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild('sort1') sort: MatSort;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private translate: TranslateService,
    private loaderService: LoaderService,
    private requestService: RequestService,
    public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
    private changeDetectorRefs: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.loadData();
    // Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			// tslint:disable-next-line:max-line-length
			debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
			distinctUntilChanged(), // This operator will eliminate duplicate values
			tap(() => {
				this.paginator.pageIndex = 0;
        this.getTableVariables();
        this.loadData();
			})
		)
		.subscribe();
		this.subscriptions.push(searchSubscription);
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (
      this.paginator.pageIndex = 0
    ));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
    - when a pagination event occurs => this.paginator.page
    - when a sort event occurs => this.sort.sortChange
    **/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => {
        this.getTableVariables();
				this.loadData();
			})
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// First Load
		// of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
		// 	this.loadData();
		// });
  }

  /** FILTRATION */
	filterConfiguration(): any {
		// const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		// filter.lastName = searchText;
    //
		// filter.name = searchText;
		// filter.email = searchText;
		// filter.firstname = searchText;
		return searchText;
	}

  refresh(): void {
    this.loadData();
  }
  clear(): void {
    this.tableData = [];
    this.tableDataTotal = 0;
  }
  public getTableVariables() {
    // this.orderBy = this.sort.active;
    // this.orderDir = this.sort.direction;
    this.pageNumber = this.paginator.pageIndex + 1;
    this.pageSize = this.paginator.pageSize;
  }
  public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let filterConfiguration = this.filterConfiguration();
      this.requestService.getDataList(this.dataType, {page: this.pageNumber , term: filterConfiguration, perpage: this.pageSize, orderbydir: this.orderDir, orderby: this.orderBy, filter: this.tableSetting.filter, fields: this.tableSetting.fields}, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.tableData = data.data;
          // this.dataSource = new MatTableDataSource<any>(data.data);
          this.dataSource = new MatTableDataSource<any>(data);

          this.dataSource.sort = this.sort;
          //console.log('this.dataSource', this.dataSource);
          if (data.pagination) {
            this.tableDataTotal = data.pagination.total;
            this.paginatorTotal.next(data.pagination.total);
          }else{
            this.paginatorTotal.next(data.length);
            this.tableDataTotal = data.length;
          }
        } else {
          this.tableData = [];
        }
        this.loading = false;
      });
    }
  }
  public sortData(e) {
    console.log(e);
  }
  public alterDataTable() {
    this.dataSource = new MatTableDataSource<UsableUser>(this.tableData);
    this.dataSource.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
  }
  public toggleModal(event: any) {
    if (event.action && event.action === 'refresh') {
      this.loadData();
    }
  }
  public create(event: any) {
    if (this.tableSetting.target === 'parent') {
      this.toggleClick('create', 'parent', this.getEmptyObject(), undefined);
    } else {
      // const dialogRef = this.dialog.open(ModalDialogComponent, {
    	// 		data: {
      //       dataType: this.dataType,
      //       title: 'Create',
      //       data: this.getEmptyObject(),
      //       modalSetting: Object.assign({}, this.tableSetting)
      //     }
    	// 	});
      // dialogRef.afterClosed().subscribe(result => {
  		// 	if (result) {
      //     this.toggleModal(result);
      //   }
      // });
    }
  }
  private getEmptyObject() {
    let newObj = {};
    for (let col of this.tableSetting.columns) {
      if ((col.editable || col.oncreate) && col.type !== 'action') {
        if (col.dataType === 'lnglat') {
          newObj[col.lng] = undefined;
          newObj[col.lat] = undefined;
        } else {
          newObj[col.sortid] = '';
        }
      }
    }
    return newObj;
  }
  public toggleClick(action, target, data, index) {
    if (target === 'parent') {
      this.onClickBtn.emit({action: action, data: data, index: index});
    } else {
      console.log('toggleClick Self', action, target, data, index);
      if (action === 'modal') {
      } else if (action === 'edit') {
        // const dialogRef = this.dialog.open(ModalDialogComponent, {
      	// 		data: {
        //       dataType: this.dataType,
        //       title: 'Edit',
        //       data: data,
        //       modalSetting: Object.assign({}, this.tableSetting)
        //     }
      	// 	});
        // dialogRef.afterClosed().subscribe(result => {
    		// 	if (result) {
        //     console.log('result', result);
        //     // temp
        //   }
        // });
      } else if (action === 'delete') {
        this.delete(data.uid);
      }
    }
  }
  public delete(id: any) {
    if (!this.loading) {
      const _title: string = this.dataTypeDisplay + ' ' + this.translate.instant('Deletion');
  		const _description: string = this.translate.instant('Are you sure you want to permanently delete this') + ' ' + this.dataTypeDisplay + '?';
      const _waitDesciption: string = this.translate.instant('Deleting') + '...';

  		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
  		dialogRef.afterClosed().subscribe(res => {
  			if (!res) {
  				return;
  			}
        this.realDelete(id);
  		});
    }
	}
  public realDelete(id: any) {
    const _deleteMessage = this.dataTypeDisplay + ' ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData(this.dataType, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
    			this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
          this.loadData();
        }
      });
    }
  }
  /**
	  * Check all rows are selected
	  */
	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.tableData.length;
		return numSelected === numRows;
	}
  /**
	  * Toggle selection
	  */
	masterToggle() {
		if (this.selection.selected.length === this.tableData.length) {
			this.selection.clear();
		} else {
			this.tableData.forEach(row => this.selection.select(row));
		}
	}
  /**
   * Fetch selected rows
   */
  // fetchData() {
  // 	const messages = [];
  // 	this.selection.selected.forEach(elem => {
  // 		messages.push({
  // 			text: `${elem.fullname}, ${elem.email}`,
  // 			id: elem.id.toString(),
  // 			status: elem.username
  // 		});
  // 	});
  // 	this.layoutUtilsService.fetchElements(messages);
  // }

  applyFilter(filterValue: string) {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
      this.dataSource.filter = filterValue;
      if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
      }
  }
}
