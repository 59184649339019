import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-years-program-view',
    templateUrl: './years-program-view.component.html',
    styleUrls: ['./years-program-view.component.scss']
})
export class YearsProgramViewComponent implements OnInit {
    public months: number;
    public years: number;
    public _withdrew: boolean = false;
    @Input()
    set withdrew(withdrew: any) {
      this._withdrew = withdrew;
      this.refresh();
    }
    get withdrew(): any {
      return this._withdrew;
    }
    public _terminated: boolean = false;
    @Input()
    set terminated(terminated: any) {
      this._terminated = terminated;
      this.refresh();
    }
    get terminated(): any {
      return this._terminated;
    }
    public _diplomadate: any;
    @Input()
    set diplomadate(diplomadate: any) {
      this._diplomadate = diplomadate;
      this.refresh();
    }
    get diplomadate(): any {
      return this._diplomadate;
    }

    public _yearValue: number = 0;
    @Input()
    set yearValue(yearValue: number) {
      this._yearValue = yearValue;
      this.refresh();
    }
    get yearValue(): number {
      return this._yearValue;
    }
    public _withdrewdate: any;
    @Input()
    set withdrewdate(withdrewdate: any) {
      this._withdrewdate = withdrewdate;
      this.refresh();
    }
    get withdrewdate(): any {
      return this._withdrewdate;
    }
    public _terminateddate: any;
    @Input()
    set terminateddate(terminateddate: any) {
      this._terminateddate = terminateddate;
      this.refresh();
    }
    get terminateddate(): any {
      return this._terminateddate;
    }
    constructor() {
    }
    ngOnInit() {}
    refresh(){
      let today = new Date();
      if(this.diplomadate){
        today = new Date(this.diplomadate);
      } else if (this.terminateddate && this.terminated){
        today = new Date(this.terminateddate);
      } else if (this.withdrewdate && this.withdrew){
        today = new Date(this.withdrewdate);
      }
      if((this.yearValue > 1800 && this.yearValue < today.getFullYear()) || (this.yearValue === today.getFullYear() && today.getUTCMonth()+1 >= 9)){
        let past = new Date(this.yearValue, 8, 1);
        let months = this.monthDiff(past, today);
        let years = Math.floor(months/12);
        if(months > 12){
          this.years = years;
          let monthDiff = months - (years * 12);
          this.months = undefined;
          this.months = monthDiff > 0 ? monthDiff : undefined ;
        }else{
          this.months = months;
          this.years = undefined;
        }
      }else{
        this.months = undefined;
        this.years = undefined;
      }
    }
    public monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth() + 1;
      months += d2.getMonth() + 1;
      return months <= 0 ? 0 : months;
    }
}
