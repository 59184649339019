import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';

class My3ErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-custom-multiple-table-select',
  templateUrl: './custom-multiple-table-select.component.html',
  styleUrls: ['./custom-multiple-table-select.component.scss']
})
export class CustomMultipleTableSelectComponent implements OnInit {
  public errorMessage: string = '';
  public loading: boolean = false;
  selectData: Array<any> = [];
  public isSubmitted: boolean = false;
  /* pagination Info */
  pageSize = 100;
  pageNumber = 1;
  orderDir = 'asc';
  orderBy = 'name';
  searchText = '';

  myControl = new FormControl();
  filteredData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  private _parentFormSubmitted = false;
  @Input() isView: boolean = false;
  @Input() clearable: boolean = false;
  @Input() required: boolean = false;
  @Input() canCash: boolean = true;
  public esMatcher = new My3ErrorStateMatcher();
  @Input() displayName: string[] = ['name', 'text'];
  @Input() filters: any;
  @Input() itemName: string = '';
  @Input() placeholder: string = '';
  @Input() dataType: string;
  @Input() apiTarget: string = 'list';
  @Input() fieldTarget: string = '';
  @Input() tableFields: any[] = [];
  public normalDisplay: string[] = ['string', 'integer', 'phone', 'number', 'email', 'textarea', 'text'];
  public competenciesDisplayedColumns: string[] = [];
  public metaFieldSetting = undefined;
  public selectedData: any[] = [];
  public _value: any[] = [];
  @Input()
  set value(value: any[]){
      this._value =  [];
      for(let val of value){
        this._value.push(val);
        this.selectedData.push(val[this.fieldTarget].uid);
      }
  }
  get value(): any[]{
    return this._value;
  }
  @Input()
  set parentFormSubmitted(parentFormSubmitted: boolean){
      this._parentFormSubmitted = parentFormSubmitted;
      if(parentFormSubmitted){
        this.form.onSubmit(undefined)
      }
  }
  get parentFormSubmitted(): boolean{
    return this._parentFormSubmitted;
  }
  @Output() onSelectReturn = new EventEmitter<any>();
  @ViewChild('dataForm') form: NgForm;
  constructor(
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService
  ) { }

  ngOnInit() {
    let fldList = [];
    for(let fld of this.tableFields){
      fldList.push(fld.name);
    }
    this.competenciesDisplayedColumns = fldList;
    this.metaFieldSetting = this.buildMetaSetting({fields: this.tableFields});
    console.log('this.metaFieldSetting', this.tableFields);
    this.loadData();
  }
  public onSubmit() {
    // do nothing
  }
  private getSelectedItem(val){
    for(let itm of this.selectData){
      if(val === itm.uid){
        return itm
      }
    }
    return '';
  }
  private getAlreadySelectedItem(val){
    for(let itm of this.value){
      if(val === itm[this.fieldTarget].uid){
        return itm
      }
    }
    return undefined;
  }
  public setParentMultipleReferenceAttribute(index, id, val) {
    this.value[index][id] = val;
    this.value = JSON.parse(JSON.stringify(this.value));
    this.onSelectReturn.emit(this.value);
  }
  public setReferenceAttribute(index, parentId, id, val) {
    // console.log(parentId, id, val);
    this.value[index][parentId][id] = val;
    this.value = JSON.parse(JSON.stringify(this.value));
    this.onSelectReturn.emit(this.value);
  }
  public setTableAttribute(index, id, val) {
    this.value[index][id] = val;
    this.value = JSON.parse(JSON.stringify(this.value));
    this.onSelectReturn.emit(this.value);
  }
  public setAttribute(val) {
    let valT = [];
    if(this.apiTarget === 'search'){
      // if(val)
      //   valT = val.uid;
      // this.onSelectReturn.emit(valT);
    }else{
      if(val)
        valT = val.value;
      let returnVal: any[] = [];
      for(let vl of valT){
        let dataAlreadySelectedObj = this.getAlreadySelectedItem(vl);
        if(dataAlreadySelectedObj){
          returnVal.push(dataAlreadySelectedObj);
        }else{
          let dataObj = this.getSelectedItem(vl);
          returnVal.push(this.getEmptyObject(dataObj));
        }
      }
      this.value = JSON.parse(JSON.stringify(returnVal));
      console.log('returnVal', returnVal);
      this.onSelectReturn.emit(returnVal);
    }
  }
  private getEmptyObject(targetSelected) {
    let newObj = {};
    for (let col of this.tableFields) {
      if(this.fieldTarget === col.name){
        newObj[col.name] = targetSelected;
      }else{
        if ((col.editable || !col.generated) && col.type !== 'action') {
          if (col.type === 'reference') {
            newObj[col.name] = {uid: '', name: ''};
          } else if(col.type === 'boolean') {
            newObj[col.name] = false;
          } else {
            newObj[col.name] = '';
          }
        }
      }
    }
    return newObj;
  }
  displayFn(data?: any): string | undefined {
    return data ? data.name : undefined;
  }
  public loadData() {
    if (this.apiTarget === 'search') {
      // this.loadDataSearch();
    }else{
      this.loadDataList();
    }
  }
  public loadDataList() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getDataL(this.dataType, (data, error) => {
        if (error) {
          this.errorMessage = error;
        }
        if (data) {
          this.selectData = data.results;
        }else {
          this.selectData = [];
        }
        this.filteredData.next(this.selectData);
        this.loading = false;
      }, this.canCash);
    }
  }
  public loadDataSearch() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let filterConfiguration = this.filters;
      this.requestService.getDataListSummary(this.dataType, {page: this.pageNumber , term: this.searchText, perpage: this.pageSize, filter: filterConfiguration}, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        if (data) {
          this.selectData = data.results;
        } else {
          this.selectData = [];
        }
        this.filteredData.next(this.selectData);
        this.loading = false;
      });
    }
  }
  termSearch(e): any {
    this.searchText = e.term;
    this.loadDataSearch();
	}
  buildMetaSetting(data, parent = undefined){
    let dataObject = {};
    let tabObject = [];
    for (let col of data.fields){
      if((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table'){
        if(parent){
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      }
    }
    return dataObject;
  }
}
