import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyDialogErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export interface DialogData {
	dataType: string;
	dataTypeTitle: string;
	title: string;
	data: any;
	modalSetting: any;
	confirmData: any;
}

@Component({
	selector: 'app-dialog-modal',
	templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss']
})
export class ModalDialogComponent implements OnInit {
	public errorMessage: string = '';
  public loading: boolean = false;
  public hasFormErrors: boolean = false;
  public esMatcher = new MyDialogErrorStateMatcher();
  public isSubmitted: boolean = true;
	constructor(
		private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData) {
			console.log('DialogData', data);
	}
	ngOnInit() {
		this.buildSetting();
	}
	private buildSetting() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
				}
				this.loading = false;
				if (data) {
          let newFields = data.results.fields;
          let idx = 0;
          if(this.data.modalSetting.hasOwnProperty('presetHiddenValues')){
            for( let fld of newFields){
                if (this.data.modalSetting.presetHiddenValues.hasOwnProperty(fld.name)){
                  newFields[idx]['visible'] = false;
                }
                idx++;
            }
          }
					this.data.modalSetting.fields = newFields;
					if(this.data.data.hasOwnProperty('uid')){
						this.loadData();
					}else{
						this.data.data = this.getEmptyObject();
					}
				} else {
					this.layoutUtilsService.showNotification('Something is Wrong', 'Dismiss');
				}
			});
		}
	}
	public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getSingleData(this.data.dataType, this.data.data['uid'], (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        if (data) {
          this.data.data = data.results;
        }
        this.loading = false;
      });
    }
  }
  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setAttributeBoolean(id, val) {
    this.data.data[id] = JSON.parse(val);
  }
  public setReferenceAttribute(parentId, id, val) {
    this.data.data[parentId][id] = val;
  }
  public setDateAttribute(id, val) {
    // console.log('setDateAttribute', id, val.toISOString());
    try{
      this.data.data[id] = val.toISOString();
    }catch(e){
      // error
    }
  }
  private getCleanObject(data) {
    let newObj = { uid: data.uid};
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action' ) {
        if (col.dataType === 'password') {
          newObj[col.name] = urlSafeBase64Encoding(data[col.name]);
        } else {
          newObj[col.name] = data[col.name];
        }
        if (this.data.modalSetting.hasOwnProperty('presetHiddenValues')) {
          if (this.data.modalSetting.presetHiddenValues.hasOwnProperty(col.name)) {
            newObj[col.name] = this.data.modalSetting.presetHiddenValues[col.name];
          }
        }
      }
    }
    return newObj;
  }
  private validateObject(data) {
    for (let col of this.data.modalSetting.fields) {
      if ((!col.nullable && !col.generated ) && col.type !== 'action' && col.visible) {
        if (col.type === 'reference') {
          if (data && data[col.name] && data[col.name]['uid'] === '') {
            console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }else if(col.type === 'tags'){
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }else{
          if (data && (data[col.name] === '' || data[col.name] === undefined)) {
            console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }
        if(col.hasOwnProperty('format')){
          try{
            if(!this.testFormat(data[col.name], col.format.name)){
              // this.layoutUtilsService.showNotification('Error:' + col.displayName + ' format does not match ' + col.format.name, 'Dismiss');
              return false;
            }
          }catch(e){
            return false;
          }
        }
      }
    }
    return true;
  }
  public testFormat(data, name){
    // let temp = data[col.name].match(col.format.value);
    if(name === 'xx:xxx:xx'){
      let temp = data.match(/^([a-zA-Z0-9]{2}\:[a-zA-Z0-9]{3}\:[a-zA-Z0-9]{3})\b/g);
      if(!temp || (temp && temp.length === 0)){
        return false;
      }
    }
    return true;
  }
  public saveData() {
		console.log(this.data.modalSetting);
    if (this.data.modalSetting.target === 'self') {
      if (!this.loading) {
        let dataUid = this.data.data.hasOwnProperty('uid') ? this.data.data.uid : undefined;
        if (this.validateObject(this.data.data)) {
          this.loading = true;
          //  this.loaderService.display(true);
          this.errorMessage = '';
          this.requestService.saveData(this.data.dataType, this.getCleanObject(this.data.data), (data, error) => {
            if (error) {
              this.errorMessage = error;
              this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
            }
            if (data) {
              if(dataUid){
    						this.layoutUtilsService.showNotification(this.data.dataTypeTitle + ' Edited Successfully.', 'Dismiss');
    					}else{
    						this.layoutUtilsService.showNotification(this.data.dataTypeTitle + ' Created Successfully.', 'Dismiss');
    					}
              this.closeModal({action: 'refresh'});
            }
            this.loading = false;
          });
        } else {
					this.layoutUtilsService.showNotification('You need to set all mandatory fields', 'Dismiss');
        }
      }
    } else if (this.data.modalSetting.target === 'parent') {
      if (this.validateObject(this.data.data)) {
        this.closeModal({action: 'select', dataType: this.data.dataType, data: this.data});
      } else {
				this.layoutUtilsService.showNotification('You need to select all mandatory fields', 'Dismiss');
      }
    }
  }
  closeModal(data): void {
    this.dialogRef.close(data);
  }
  public toggleClick(action, target, data) {
    if (target === 'parent') {
      if (this.validateObject(this.data.data)) {
        this.closeModal({action: action, data: this.data.data, value: data});
      } else {
				this.layoutUtilsService.showNotification('You need to select all mandatory fields', 'Dismiss');
      }
    } else {
      // console.log('toggleClick Self', action, target, data);
      if (action === 'close') {
        this.closeModal(undefined);
      }
    }
  }
	private getEmptyObject() {
    let newObj = {};
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.type === 'reference') {
          newObj[col.name] = {uid: '', name: ''};
        } else if(col.type === 'boolean') {
          newObj[col.name] = false;
        } else if(col.type === 'tags') {
          newObj[col.name] = [];
        } else {
          newObj[col.name] = '';
        }
        if (this.data.modalSetting.hasOwnProperty('presetValues')) {
          if (this.data.modalSetting.presetValues.hasOwnProperty(col.name)) {
            if (col.type === 'reference') {
		          newObj[col.name] = { uid: this.data.modalSetting.presetValues[col.name], name: ''};
		        } else if(col.type === 'boolean') {
		          newObj[col.name] = this.data.modalSetting.presetValues[col.name];
		        } else {
		          newObj[col.name] = this.data.modalSetting.presetValues[col.name];
		        }
          }
        }
      }
    }
    return newObj;
  }
}
