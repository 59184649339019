import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef, ViewChild, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { fromEvent, BehaviorSubject, merge, Subscription, Observable, Observer } from 'rxjs';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

interface ViewDialogData {
	title: string;
	data: any;
	currentImage: string;
}

@Component({
	selector: 'app-gallery-dialog-modal',
	templateUrl: './custom-gallery-dialog.component.html',
  styleUrls: ['./custom-gallery-dialog.component.scss']
})
export class ModalGalleryDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];
	public errorMessage: string = '';
  public loading: boolean = false;
  public hasFormErrors: boolean = false;
  public dataType: string = 'img';
  public dataTypeDisplay: string = 'Image';
  public dataList: any[] = [];
  public dataListFolder: any[] = [];
	public imageSelected: any = undefined;
	public imageUrlPath: any = undefined;
	public selectedRatio: number = 1;
	public folderSelected: string = '';
	public tabSelected: number = 0;
	public searchVal: string = '';
  public imageChangedEvent: any = '';
  public croppedImage: any = '';
  public currentFile: any = {error: false, text: this.makeid(10), originalFile: undefined};
  public allowedExtensions: string[] = ['jpeg', 'jpg', 'bmp', 'png'];
	public selectedUser: any;
	public pictureLink: string = 'assets/images/profile.jpg';

	public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	pageSize = 10;
	pageNumber = 1;

	orderDir = 'asc';
	orderBy = '_id'; // uid

	// @ViewChild('searchInput') searchInput: ElementRef;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
	constructor(private zone: NgZone, private translate: TranslateService,
		private requestService: RequestService,
		private cdr: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalGalleryDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
			// console.log('ModalGalleryDialogComponent', data);
			if(this.data.currentImage){
				this.setImageSelected(this.data.currentImage);
			}
	}

	ngOnInit() {
	}
	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	public setImageSelected(val) {
    this.imageSelected = val;
		this.getBase64ImageFromURL(val).subscribe(base64data => {
		   // this is the image as dataUrl
		   this.imageUrlPath = 'data:image/jpg;base64,' + base64data;
		 });
  }
  closeModal(data): void {
		this.zone.run(() => {
		   this.dialogRef.close(data);
		});
  }
	onBrowseFiles(target: any): void {
      this.readFiles(target.files);
  }
  /**
   *  @param files: list of browsed files
   *  @param index: iterator over browsed images
   *
   *  read files browsed by user
   */
	 readFiles(files, index = 0 ): void {
 			let reader = new FileReader();
 			if (index in files) {
 				let currentFile = {error: false, text: files[index].name.split('.')[0], originalFile: files[index]};
 				let fileExt = files[index].name.split('.').pop();
 				const max_size = 5000000;
 				if (files[index].size > max_size) {
 					this.layoutUtilsService.showNotification( 'Maximum size allowed is ' + max_size / 1000000 + 'Mb', 'Dismiss');
 				}else if (this.allowedExtensions.indexOf(fileExt) === -1) {
 					currentFile.error = true;
 					this.layoutUtilsService.showNotification( 'The file type is not allowed.', 'Dismiss');
 				} else {
 					this.readFile(files[index], reader, (event) => {
						this.imageUrlPath = event;
 					});
 				}
				this.currentFile = currentFile;
 			} else {
 				this.cdr.detectChanges();
 			}
 	}
 	readFile(file, reader, callback): void {
 					reader.onload = () => {
 									callback(reader.result);
 					}
 					reader.readAsDataURL(file);
 	}
 	readImage(file, image, callback): void {
 					image.onload = () => {
 									callback(image);
 					}
 					image.src=file;
 	}
	continueUpload(currentFile){
		this.loading = true;
		this.requestService.onUploadUserImage(currentFile, this.data.data)
		.subscribe(
						(results: any) => {
							// console.log('currentFile', currentFile);
							if (results['status']) {
								currentFile.source_url = results.results.url;
								this.layoutUtilsService.showNotification( 'Image Uploaded Successfully.', 'Dismiss');
								this.closeModal(results.results.url);
							}else {
								currentFile.error = true;
								this.layoutUtilsService.showNotification('Error:' + results['message'], 'Dismiss');
							}
							this.loading = false;
							this.cdr.detectChanges();
						},
						(error: any) => {
							console.log('Error uploading file.', error);
							currentFile.error = true;
							this.loading = false;
							this.layoutUtilsService.showNotification('Error: Error uploading file.' , 'Dismiss');
							this.cdr.detectChanges();
						}
		);
	}
	uploadSelectedImage(isCrop: boolean = false){
		this.loading = false;
		let currentFile = this.currentFile;
		let fileToUpload = this.imageUrlPath;
		if(isCrop){
			let croppedImage = this.imageCropper.crop();
			fileToUpload = croppedImage.base64;
		}
		const max_height = 800;
		const max_width = 800;
		var image = new Image();
		this.readImage(fileToUpload, image, (imgresult) => {
			// console.log('readImage' , isCrop, imgresult);
			var canvas=document.createElement("canvas");
			var context=canvas.getContext("2d");
			var ratio = Math.min(max_width / imgresult.width, max_height / imgresult.height);
			if(imgresult.width < imgresult.width*ratio && imgresult.height < imgresult.height*ratio){
				canvas.width = imgresult.width;
				canvas.height= imgresult.height;
			}else{
				canvas.width = imgresult.width*ratio;
				canvas.height= imgresult.height*ratio;
			}
			context.drawImage(image,
					0,
					0,
					image.width,
					image.height,
					0,
					0,
					canvas.width,
					canvas.height
			);
			currentFile['originalFile'] = this.dataURLtoFile(canvas.toDataURL('image/jpeg', 0.5), currentFile.text + '.jpeg');
			if(!this.loading){
					this.continueUpload(currentFile);
			}
		});
	}
	dataURLtoFile(dataurl, filename) {
			var arr = dataurl.split(','),
					mime = arr[0].match(/:(.*?);/)[1],
					bstr = atob(arr[1]),
					n = bstr.length,
					u8arr = new Uint8Array(n);

			while(n--){
					u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, {type: mime});
	}
	makeid(length) {
		 let result           = '';
		 let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		 let charactersLength = characters.length;
		 for ( let i = 0; i < length; i++ ) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength));
		 }
		 return result;
	}
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  imageHasLoaded(e) {
      console.log('imageHasLoaded', e);
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

	getBase64ImageFromURL(url: string) {
   return Observable.create((observer: Observer<string>) => {
	     // create an image object
	     let img = new Image();
	     img.crossOrigin = 'Anonymous';
	     img.src = url;
	     if (!img.complete) {
	         // This will call another method that will create image from url
	         img.onload = () => {
	         observer.next(this.getBase64Image(img));
	         observer.complete();
	       };
	       img.onerror = (err) => {
	          observer.error(err);
	       };
	     } else {
	         observer.next(this.getBase64Image(img));
	         observer.complete();
	     }
	   });
	}
	getBase64Image(img: HTMLImageElement) {
	   // We create a HTML canvas object that will create a 2d image
	   var canvas = document.createElement("canvas");
	   canvas.width = img.width;
	   canvas.height = img.height;
	   var ctx = canvas.getContext("2d");
	   // This will draw image
	   ctx.drawImage(img, 0, 0);
	   // Convert the drawn image to Data URL
	   var dataURL = canvas.toDataURL("image/jpeg", 0.5);
		 return dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
	}
	public deleteFile() {
			const _deleteMessage = 'Image ' + this.translate.instant('Cleared Successfully') + '.';
			if (!this.loading) {
					this.loading = true;
					this.errorMessage = '';
					this.requestService.deleteUserImage( this.data.data, (data, error) => {
							if (error) {
									this.errorMessage = error;
									this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
							}
							this.loading = false;
							if (data) {
									this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
									this.closeModal(this.pictureLink);
							}
					});
			}
	}
}
