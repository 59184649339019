import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import { MyDialogErrorStateMatcher, DialogData } from '../custom-dialog/custom-dialog.component';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-offering-dialog-modal',
	templateUrl: './custom-offering-dialog.component.html',
  styleUrls: ['./custom-offering-dialog.component.scss']
})
export class OfferingModalDialogComponent implements OnInit {
	public errorMessage: string = '';
  public loading: boolean = false;
  public hasFormErrors: boolean = false;
  public esMatcher = new MyDialogErrorStateMatcher();
  public isSubmitted: boolean = false;
  public fieldsDetails: any = undefined;
	public metaYearSetting: any[] = [];
	// public metaIDXSetting: any[] = [];
	public courseFilter: any;
	public instructorFilter: any;
	private customKeys: any = environment.customKeys;
	constructor(
		private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<OfferingModalDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData) {
			console.log('DialogData', data);
	}
	ngOnInit() {
		this.buildSetting();
		let currentYear = Number((new Date()).getFullYear());
		this.metaYearSetting = [];
		let metaYearSetting = [];
		for(let i = currentYear - 10; i <= currentYear + 10; i++){
			 metaYearSetting.push(i);
		}
		this.metaYearSetting = metaYearSetting;
		// this.metaYearSetting = metaYearSetting;
		// let metaIDXSetting = [];
		// for(let i = 1; i <= 10; i++){
		// 	 metaIDXSetting.push(i);
		// }
		// this.metaIDXSetting = metaIDXSetting;


		// let filters1 = {'$and': []};
		// filters1['$and'].push({"role": {"$eq": this.customKeys.roleSupervisor}});
		// this.courseFilter = filters1;
		let filters2 = {'$and': []};
		filters2['$and'].push({"roles": {"$contains": [this.customKeys.roleSupervisor]}});
		this.instructorFilter = filters2;
	}
	private buildSetting() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
				}
				this.loading = false;
				if (data) {
          let newFields = data.results.fields;
					this.data.modalSetting.fields = newFields;
					this.fieldsDetails = this.getFieldsDetails(this.data.modalSetting);
					if(this.data.data.hasOwnProperty('uid')){
						this.loadData();
					}else{
						this.data.data = this.getEmptyObject();
					}
				} else {
					this.layoutUtilsService.showNotification('Something is Wrong', 'Dismiss');
				}
			});
		}
	}
	getFieldsDetails(data) {
		let fieldsDetails = {};
		if(data.hasOwnProperty('fields')){
			for(let fld of data.fields){
				fieldsDetails[fld.name] = fld;
			}
			return fieldsDetails;
		}
		return undefined;
  }
	public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getSingleData(this.data.dataType, this.data.data['uid'], (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        if (data) {
          this.data.data = data.results;
        }
        this.loading = false;
      });
    }
  }
  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setAttributeBoolean(id, val) {
    this.data.data[id] = JSON.parse(val);
  }
  public setReferenceAttribute(parentId, id, val) {
    this.data.data[parentId][id] = val;
  }
  public setDateAttribute(id, val) {
    // console.log('setDateAttribute', id, val.toISOString());
    try{
      this.data.data[id] = val.toISOString();
    }catch(e){
      // error
    }
  }
  private getCleanObject(data) {
    let newObj = { uid: data.uid};
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action' ) {
        if (col.dataType === 'password') {
          newObj[col.name] = urlSafeBase64Encoding(data[col.name]);
        } else {
          newObj[col.name] = data[col.name];
        }
      }
    }
    return newObj;
  }
  private validateObject(data) {
    for (let col of this.data.modalSetting.fields) {
      if ((!col.nullable && !col.generated ) && col.type !== 'action' && col.visible) {
        if (col.type === 'reference') {
          if (data && data[col.name] && data[col.name]['uid'] === '') {
            console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }else if(col.type === 'tags'){
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }else{
          if (data && (data[col.name] === '' || data[col.name] === undefined)) {
            console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }
      }
    }
    return true;
  }
  public saveData() {
      if (!this.loading) {
				this.isSubmitted = true;
				let dataUid = this.data.data.hasOwnProperty('uid') ? this.data.data.uid : undefined;
        if (this.validateObject(this.data.data)) {
          this.loading = true;
          //  this.loaderService.display(true);
          this.errorMessage = '';
          this.requestService.saveData(this.data.dataType, this.getCleanObject(this.data.data), (data, error) => {
            if (error) {
              this.errorMessage = error;
              this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
            }
            if (data) {
							if(dataUid){
								this.layoutUtilsService.showNotification('Course Offering Edited Successfully.', 'Dismiss');
							}else{
								this.layoutUtilsService.showNotification('Course Offering Created Successfully.', 'Dismiss');
							}
              this.closeModal({action: 'refresh'});
            }
            this.loading = false;
          });
        } else {
					this.layoutUtilsService.showNotification('You need to set all mandatory fields', 'Dismiss');
        }
      }
  }
  closeModal(data): void {
    this.dialogRef.close(data);
  }
  public toggleClick(action, target, data) {
    if (target === 'parent') {
      if (this.validateObject(this.data.data)) {
        this.closeModal({action: action, data: this.data.data, value: data});
      } else {
				this.layoutUtilsService.showNotification('You need to select all mandatory fields', 'Dismiss');
      }
    } else {
      console.log('toggleClick Self', action, target, data);
      if (action === 'close') {
        this.closeModal(undefined);
      }
    }
  }
	private getEmptyObject() {
    let newObj = {};
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.type === 'reference') {
          newObj[col.name] = { uid: '', name: ''};
        } else if(col.type === 'boolean') {
          newObj[col.name] = false;
        } else if(col.type === 'number') {
          newObj[col.name] = 0;
        } else {
          newObj[col.name] = '';
        }
        if (this.data.modalSetting.hasOwnProperty('presetValues')) {
          if (this.data.modalSetting.presetValues.hasOwnProperty(col.name)) {
						if (col.type === 'reference') {
		          newObj[col.name] = { uid: this.data.modalSetting.presetValues[col.name], name: ''};
		        } else if(col.type === 'boolean') {
		          newObj[col.name] = this.data.modalSetting.presetValues[col.name];
		        } else {
		          newObj[col.name] = this.data.modalSetting.presetValues[col.name];
		        }
          }
        }
      }
    }
    return newObj;
  }
}
