import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { urlSafeBase64Encoding, isValidHttpUrl, guid, getFileName } from '../../../shared/helpers';
import { ConfirmEntityDialogComponent } from '../modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { Moment } from 'moment';
import * as moment from 'moment';

class MyDialogErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

interface DialogData {
  title: string;
  data: any;
  type: string;
  canEdit: boolean;
  field1: string;
  field2: string;
  userId: string;
  confirmData: any;
}

@Component({
  selector: 'app-multiple-attachments-dialog-modal',
  templateUrl: './custom-multiple-attachments-dialog.component.html',
  styleUrls: ['./custom-multiple-attachments-dialog.component.scss']
})
export class ModalMultipleAttachmentsDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public errorMessage: string = '';
  private organization: any = undefined;
  public selectedUser: any;
  public loading: boolean = false;
  public esMatcher = new MyDialogErrorStateMatcher();
  public allowedExtensions: string[] = ['docx', 'doc', 'pdf'];
  public isSubmitted: boolean = true;
  public metaFieldSetting = undefined;
  public attachmentListTemp: any[] = [];
  public attachmentList: any[] = [];
  public originalData = undefined;
  public selectedItem: any = undefined;
  public modalSetting = { fields: [], customSettings: {} };
  @ViewChild('fileselect') fileselect: ElementRef;
  constructor(private translate: TranslateService, public dialog: MatDialog,
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService, public changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ModalMultipleAttachmentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    //console.log('DialogData', data);
  }
  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.buildSetting();
        }
      })
    );
  }
  closeModal(data): void {
    this.dialogRef.close(data);
  }
  openUrl(url): void {
    if (url && url !== '') {
      let newUrl = url.trim();
      // if(newUrl.indexOf('https:') == -1){
      //   newUrl = newUrl.replace('http:', 'https:')
      // }
      window.open(newUrl, '_blank');
    }

  }
  addAttachment(): void {
    this.attachmentList.unshift({ id: '', name: '', type: '', url: '', thumbnail: '', linked: false });
  }
  removeAttachment(idx): void {
    this.attachmentList.splice(idx, 1);
  }
  public setType(index, val) {
    let selectedData = JSON.parse(JSON.stringify(this.attachmentList));
    let idx = 0;
    for (let dt of selectedData) {
      if (idx === index) {
        selectedData[idx]['url'] = '';
        selectedData[idx]['type'] = val;
      }
      idx++;
    }
    this.attachmentList = JSON.parse(JSON.stringify(selectedData));
  }
  private buildSetting() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.modalSetting['fields'] = [];

      this.requestService.getMetaData('docs', undefined, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          let newFields = data.results.fields;
          this.metaFieldSetting = this.buildMetaSetting(data.results, parent = undefined);
          let idx = 0;
          if (this.modalSetting.hasOwnProperty('customSettings')) {
            for (let fld of newFields) {
              if (this.modalSetting.customSettings.hasOwnProperty(fld.name)) {
                newFields[idx]['visible'] = this.modalSetting.customSettings[fld.name].visible;
              }
              idx++;
            }
          }
          this.modalSetting.fields = newFields;

          // console.log('this.data', this.data);
          this.attachmentList = [];
          if (this.data.field1 && this.data.field2) {
            this.loadData();
          }
        } else {
          console.log(this.translate.instant('Something is Wrong'));
        }
      });
    }
  }
  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    // let tabObject = [];
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      } else if (col.type === 'object') {
        dataObject[col.name] = this.buildMetaSetting(col);
      }
      else if (col.type === 'table') {
        dataObject[col.name] = col;
      }
    }
    return dataObject;
  }
  public getfield() {
    return this.data.field1 + '-' + this.data.field2;
  }
  public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let dataId = 'list/' + this.data.userId + '/' + this.getfield();
      this.requestService.getSingleData('docs', dataId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.data.data = data.results;
          this.originalData = JSON.parse(JSON.stringify(this.data.data));
          this.attachmentList = data.results;
        }
        this.loading = false;
      });
    }
  }
  private getCleanDataObject(currentObject) {
    let newObj: any = currentObject;
    for (let col of this.modalSetting.fields) {
      if (col.type === 'datetime' && newObj.hasOwnProperty(col.name)) {
        newObj[col.name] = moment.utc(newObj[col.name]).local().format('YYYY-MM-DDTHH:mm');
      } else if (col.type === 'tags' && !newObj.hasOwnProperty(col.name)) {
        newObj[col.name] = [];
      } else if (col.type === 'boolean' && col.name === 'isPasswordRequired' && newObj['isSuperAdmin']) {
        newObj[col.name] = true;
      }
      if (newObj[col.name] === null || newObj[col.name] === undefined) {
        if (col.type === 'reference') {
        } else if (col.type === 'boolean') {
          newObj[col.name] = false;
        } else if (col.type === 'color') {
          newObj[col.name] = '#ffffff';
        } else if (col.type === 'picturearray') {
          newObj[col.name] = [];
        } else if (col.type === 'maparray' || col.type === 'tags') {
          newObj[col.name] = [];
        } else if (col.type === 'datetime') {
          newObj[col.name] = moment.utc().format('YYYY-MM-DDTHH:mm');
        } else {
          newObj[col.name] = '';
        }
      }
      // if (col.name === 'settings') {
      //   if (!newObj[col.name] || (newObj[col.name] && !newObj[col.name]['attachmentList'])) {
      //     newObj[col.name] = JSON.parse(JSON.stringify(this.defaultSettings));
      //   }
      // }
    }
    return newObj;
  }
  public setDateAttribute(id, val) {
    // console.log('setDateAttribute', id, val.toISOString());
    try {
      this.data.data[id] = val.toISOString();
    } catch (e) {
      // error
    }
  }
  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setAttributeBoolean(id, val) {
    this.data.data[id] = JSON.parse(val);
  }
  public setReferenceAttribute(parentId, id, val) {
    this.data.data[parentId][id] = val;
  }
  public setMultipleReferenceAttribute(id, val) {
    this.data.data[id] = val;
  }
  private getCleanObject(data) {
    let newObj: any = { _id: data._id };
    for (let col of this.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.dataType === 'password') {
          newObj[col.name] = urlSafeBase64Encoding(data[col.name]);
        } else if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            if (data[col.name] && data[col.name].length > 0)
              newObj[col.name] = data[col.name];
          } else {
            if (data[col.name] !== '')
              newObj[col.name] = data[col.name];
          }
        } else if (col.type === 'datetime') {
          newObj[col.name] = moment(data[col.name]).utc().format('YYYY-MM-DDTHH:mm');
        } else {
          newObj[col.name] = data[col.name];
        }
        // if (this.data.modalSetting.hasOwnProperty('customSettings')) {
        //   if (this.data.modalSetting.customSettings.hasOwnProperty(col.name)) {
        //     newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
        //   }
        // }
      }
    }

    return newObj;
  }
  private validateObject(data: any) {
    for (let col of this.modalSetting.fields) {
      if ((!col.nullable && !col.generated) && col.type !== 'action' && col.name !== 'privacyterm' && col.visible) {
        if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            // console.log('col.name', col.name, data[col.name] );
            if (col.name === 'resources') {
              if (data && data[col.name] && data[col.name].length === 0) {
                return false;
              }
              if (data[col.name][0]['_id'] === '') {
                return false;
              }
            } else {
              if (data && data[col.name] && data[col.name].length === 0) {
                return false;
              }
            }
          } else {
            if (data && data[col.name] && data[col.name]['_id'] === '') {
              // console.log('col.name', col.name, data[col.name] );
              return false;
            }
          }
        } else if (col.type === 'tags') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === 'picturearray') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === 'email') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined || !this.isEmailValid(data[col.name]))) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === 'url') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else {
          if (data && (data[col.name] === '' || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }
      }
    }
    return true;
  }
  isValidUrl(url, extensions: string[]) {
    for (let ext of extensions) {
      if (url.toLowerCase().indexOf(ext) > -1) {
        return true;
      };
    }
    return false;
  }
  private validateLogic(data) {
    for (let col of this.modalSetting.fields) {
      if (!col.generated && col.type !== 'action' && col.name !== 'privacyterm' && col.visible) {
        if (col.hasOwnProperty('charlimit')) {
          if (data && data[col.name] && data[col.name].length > col.charlimit) {
            return this.translate.instant(col.displayName + ' should have a maximum characters of ' + col.charlimit);
          }
        } else {
          if (col.type === 'textarea') {
            if (data && data[col.name] && data[col.name].length > 1000) {
              return this.translate.instant(col.displayName + ' should have a maximum characters of 1000');
            }
          }
        }
        if (col.type === 'url') {
          if (data && data[col.name] && data[col.name].length > 0) {
            if (!isValidHttpUrl(data[col.name])) {
              return this.translate.instant('Fill in the correct URL link format');
            }
          }
        }
      }
      if (((!col.nullable || (col.nullable && col.type === 'boolean')) && !col.generated) && col.type !== 'action' && col.name !== 'privacyterm' && col.visible) {
        if (col.hasOwnProperty('validation')) {
          for (let vald of col.validation) {
            if (vald.operator === 'lt') {
              if (col.type === 'datetime') {
                // console.log('data[vald.target]', data[vald.target]);
                // console.log('moment(data[col.name]', data[col.name]);
                // console.log('minutes', moment(data[vald.target]).diff(moment(data[col.name]), 'minutes'));
                if (!(data[col.name] && data[vald.target] && moment(data[vald.target]).diff(moment(data[col.name]), 'minutes') > 0)) {
                  return col.displayName + ' should be less than ' + this.metaFieldSetting[vald.target]['displayName'];
                }
              } else {
                if (!(data[col.name] && data[vald.target] && data[col.name] < data[vald.target])) {
                  return col.displayName + ' should be less than ' + this.metaFieldSetting[vald.target]['displayName'];
                }
              }
            } else if (vald.operator === 'gt') {
              if (col.type === 'datetime') {
                if (!(data[col.name] && data[vald.target] && moment(data[col.name]).diff(moment(data[vald.target]), 'minutes') > 0)) {
                  return col.displayName + ' should be greater than ' + this.metaFieldSetting[vald.target]['displayName'];
                }
              } else {
                if (!(data[col.name] && data[vald.target] && data[col.name] < data[vald.target])) {
                  return col.displayName + ' should be greater than ' + this.metaFieldSetting[vald.target]['displayName'];
                }
              }
            } else if (vald.operator === 'eq') {
              if (data[col.name] !== data[vald.target]) {
                if (vald.hasOwnProperty('value')) {
                  if (data[col.name] !== vald.value) {
                    return col.displayName + ' should be equal to ' + this.metaFieldSetting[vald.target]['displayName'];
                  }
                } else {
                  return col.displayName + ' should be equal to ' + this.metaFieldSetting[vald.target]['displayName'];
                }
              }
            } else if (vald.operator === 'url') {
              if (!this.isUrlValid(data[col.name])) {
                return col.displayName + ' should url format.';
              }
            }
          }
        }
      }
    }
    return undefined;
  }
  public saveData(byPassMessage = false) {
    // console.log('saveData', this.data.data);
    // console.log('this.data.modalSetting', this.data.modalSetting);
    // console.log('getCleanObject', this.getCleanObject(this.data.data));
    if (!this.loading) {
      let attachmentList = [];
      for (let fl of this.attachmentList) {
        if (!fl.name) {
          fl.name = '';
          if (!byPassMessage) {
            this.layoutUtilsService.showNotification(this.translate.instant('Title should be not empty!'), this.translate.instant('Dismiss'));
            return;
          }
        }
        if (!fl.description) {
          fl.description = '';
        }
        if (!fl.date) {
          fl.date = '';
        }
        attachmentList.push({
          uid: fl.uid,
          name: fl.name.trim(),
          description: fl.description.trim(),
          date: fl.date
        });
      }
      if (attachmentList.length > 0) {
        let postObject = { docs: attachmentList }
        try {
          // this.data.data['settings']['attachmentList'] = JSON.parse(JSON.stringify(this.attachmentList));
          this.loading = true;
          this.errorMessage = '';
          this.requestService.saveDocsData('docs/updateupload', this.data.userId, this.getfield(), postObject, (data, error) => {
            if (error) {
              this.errorMessage = error;
              this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
            }
            this.loading = false;
            if (data) {
              this.data.data = data.results;
              this.originalData = JSON.parse(JSON.stringify(this.data.data));
              this.attachmentList = data.results;
              if (!byPassMessage) {
                if (this.data.type === 'topic') {
                  this.layoutUtilsService.showNotification('Items' + ' ' + this.translate.instant('saved successfully'), this.translate.instant('Dismiss'));
                } else {
                  this.layoutUtilsService.showNotification('Attachments' + ' ' + this.translate.instant('saved successfully'), this.translate.instant('Dismiss'));
                }
              }
              // this.closeModal({ action: 'refresh', data: data });
            }
          });
        } catch (e) {
          this.layoutUtilsService.showNotification(this.translate.instant('Something is wrong contact the administrator'), this.translate.instant('Dismiss'));
        }
      }
    }
  }
  private getEmptyObject() {
    let newObj = {};
    for (let col of this.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            if (col.name === 'resources') {
              newObj[col.name] = [{ _id: '', name: '' }];
            } else {
              newObj[col.name] = [];
            }
          } else {
            newObj[col.name] = { _id: '', name: '' };
          }
        } else if (col.type === 'boolean') {
          newObj[col.name] = false;
        } else if (col.type === 'color') {
          newObj[col.name] = '#ffffff';
        } else if (col.type === 'picturearray') {
          newObj[col.name] = [];
        } else if (col.type === 'maparray' || col.type === 'tags') {
          newObj[col.name] = [];
        } else if (col.type === 'datetime') {
          newObj[col.name] = moment.utc().format('YYYY-MM-DDTHH:mm');
          // } else if (col.type === 'json') {
          //   newObj[col.name] = JSON.parse(JSON.stringify(this.defaultSettings));
        } else {
          newObj[col.name] = '';
        }
        if (this.modalSetting.hasOwnProperty('customSettings')) {
          if (this.modalSetting.customSettings.hasOwnProperty(col.name)) {
            newObj[col.name] = this.modalSetting.customSettings[col.name].value;
          }
        }
      }
    }
    return newObj;
  }
  private isUrlValid(url) {
    var re = /^((http[s]?):\/)+\/?([^:\/\s]?)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/;
    return re.test(String(url).toLowerCase());
  }
  private isEmailValid(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  getEndId(url) {
    let pos = url.lastIndexOf('/');
    let id = pos === -1 ? url : url.substr(pos + 1, url.length - 1);
    return id;
  }
  insertAttachment(id: string, name: string, url: string, type: string, thumbnail: string, albumId: string = undefined) {
    let attachmentList = JSON.parse(JSON.stringify(this.attachmentList));
    for (let vid of attachmentList) {
      if (vid.url === url) {
        return;
      }
    }
    this.attachmentListTemp.push({ id: id, name: name, type: type, url: url, thumbnail: thumbnail, playlistId: albumId, linked: true });
  }
  addNewRow() {
    this.attachmentList.push({
      date: null,
      description: "",
      field: this.getfield(),
      name: "",
      url: "",
      userId: this.data.userId,
      user_uid: this.data.userId
    });
    this.saveData(true);
  }
  /**
  *  @param target: trigger event
  *
  *  trigger read files browsed files
  */
  onBrowseFiles(target: any): void {
    this.readFiles(target.files);
  }

  /**
   *  @param files: list of browsed files
   *  @param index: iterator over browsed images
   *
   *  read files browsed by user
   */
  readFiles(files, index = 0): void {
    // let reader = new FileReader();
    if (index in files) {
      let currentFile = { error: false, text: files[index].name.split('.')[0], id: files[index].id, originalFile: files[index], source_url: null };
      let fileExt = files[index].name.split('.').pop();
      if (this.allowedExtensions.indexOf(fileExt) === -1) {
        currentFile.error = true;
        this.layoutUtilsService.showNotification('Error: File extension not supported', 'Dismiss');
      } else {
        this.loading = true;
        let selectedItem = { uid: undefined, name: currentFile.text };
        if (this.selectedItem !== undefined && this.selectedItem > -1) {
          selectedItem = this.attachmentList[this.selectedItem];
          // if (selectedItem.name && selectedItem.name !== '') {
          //   currentFile.text = selectedItem.name;
          // }
        }
        this.requestService.onUploadUserDocs(currentFile, this.data.userId, this.getfield(), selectedItem.uid)
          .subscribe(
            (results: any) => {
              // console.log('currentFile', results);
              if (results['status']) {
                currentFile.source_url = results.results.fileLink;
                if (this.selectedItem !== undefined && this.selectedItem > -1) {
                  this.attachmentList = this.attachmentList.map((itm) => {
                    if (itm.uid === results.results.uid) {
                      return results.results;
                    } else {
                      return itm;
                    }
                  });
                } else {
                  this.attachmentList.push(results.results);
                }
                this.layoutUtilsService.showNotification('File uploaded successfully', 'Dismiss');
              } else {
                currentFile.error = true;
                this.layoutUtilsService.showNotification('Error:' + results['message'], 'Dismiss');
              }
              this.loading = false;
              this.selectedItem = undefined;
              this.fileselect.nativeElement.value = "";
              this.changeDetectorRef.detectChanges();
            },
            (error: any) => {
              console.log('Error uploading file.', error);
              currentFile.error = true;
              this.layoutUtilsService.showNotification('Error: Error uploading file.', 'Dismiss');
              this.loading = false;
              this.selectedItem = undefined;
              this.fileselect.nativeElement.value = "";
              this.changeDetectorRef.detectChanges();
            }
          );
      }
    } else {
      this.fileselect.nativeElement.value = "";
      this.changeDetectorRef.detectChanges();
    }
  }
  selectFile(uid, index) {
    this.selectedItem = index;
    this.fileselect.nativeElement.click();
  }
  deleteFile(uid, index) {
    if (!this.loading) {
      const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
        width: '300px',
        data: {
          title: 'Delete File',
          description: 'Are you sure you want to delete this file?',
          cancelbtn: 'Cancel',
          confirmbtn: 'Delete'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // console.log('result', result);
          if (result) {
            this.confirmDelete(uid, index);
          }
        }
      });
    }
  }
  public confirmDelete(uid, index) {
    const _deleteMessage = `File Deleted Successfully.`;
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData('docs', uid, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        this.loading = false;
        if (data) {
          let attachmentListlet = JSON.parse(JSON.stringify(this.attachmentList));
          attachmentListlet.splice(index, 1);
          this.attachmentList = attachmentListlet;
          this.layoutUtilsService.showNotification(_deleteMessage, 'Dismiss');
        }
      });
    }
  }
}
