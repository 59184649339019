import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, ElementRef, OnDestroy} from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { SnackBarComponent } from '../../../shared/components/snack-bar/snack-bar.component';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';

import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { environment } from '../../../../environments/environment';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss']
})
export class CustomFormComponent implements OnInit, OnDestroy {
	private subscriptions: Subscription[] = [];
	private customKeys: any = environment.customKeys; // used in view
	public permission: any[] = [environment.customKeys.roleAdmin];
  public metaFieldSetting = [];
  public metaFieldTabsSettingTemp = [];
  public metaFieldTabsSetting = [];
  public errorMessage: string = '';
  public hasFormErrors: boolean = false;
  public isSubmitted: boolean = false;
  @Input() loading: boolean = false;
  public esMatcher = new MyErrorStateMatcher();
  // @Input() user: boolean = false;
  tableData: any = undefined;
  tableDataTemp: any = undefined;
  private dataType: string = 'user';
  @Input() profileDisplay: boolean = true;
  @Input() userId: string = undefined;
  private _tableSetting: any = undefined;
  @Input()
  set tableSetting(tableSetting: any) {
    this._tableSetting = tableSetting;
    if(tableSetting){
      // console.log('this.tableSetting', tableSetting);
      this.metaFieldSetting = this.buildMetaSetting(tableSetting);
      // console.log('this.metaFieldSetting', this.metaFieldSetting);
      // console.log('this.metaFieldTabsSettingTemp', this.metaFieldTabsSettingTemp);
      if(this.userId){
        this.loadUser();
      }else{
        this.initializeUser();
      }
    }
  }
  get tableSetting(): any {
    return this._tableSetting;
  }
  @Output() returnDataObjectCreate = new EventEmitter<any>();
  @Output() returnDataObjectEdit = new EventEmitter<any>();
  @Output() returnDataObjectDelete = new EventEmitter<any>();
  @Output() returnAction = new EventEmitter<any>();
  public group: FormGroup;
  constructor(
    private loaderService: LoaderService,
    private requestService: RequestService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private layoutUtilsService: LayoutUtilsService,
    private changeDetectorRefs: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  public hasUidRole(uid){
    if(this.tableData['roles']){
      for(let rl of this.tableData['roles']) {
        if(rl.uid === uid){
          return true;
        }
      }
    }
    return false;
  }
  public hasTargetRole(targetfield){
    if(this.tableData['roles']){
      for(let rl of this.tableData['roles']) {
        if(rl.targetfield === targetfield){
          return true;
        }
      }
    }
    return false;
  }
  public setAttribute(id, val) {
    // console.log(id, val);
    this.tableData[id] = val;
  }
  public setParentAttribute(parentId, id, val) {
    // console.log(id, val);
    this.tableData[parentId][id] = val;
  }
  public setDateAttribute(id, val) {
    // console.log('setDateAttribute', id, val.toISOString());
    try{
      this.tableData[id] = val.toISOString();
    }catch(e){
      // error
    }
  }
  public setParentDateAttribute(parentId, id, val) {
    // console.log('setDateAttribute', id, val.toISOString());
    try{
      this.tableData[parentId][id] = val.toISOString();
    }catch(e){
      // error
    }
  }
  public setReferenceAttribute(parentId, id, val) {
    // console.log(parentId, id, val);
    this.tableData[parentId][id] = val;
    // console.log(this.tableData);
  }
  public setParentReferenceAttribute(superparentId, parentId, id, val) {
    // console.log(parentId, id, val);
    this.tableData[superparentId][parentId][id] = val;
    // console.log(this.tableData);
  }
  public setRoleReferenceAttribute(parentId, val) {
    // console.log(parentId, id, val);
    this.tableData[parentId] = val;
    this.buildTabsMetaSetting() ;
    // console.log(this.tableData);
  }
  public setAttributeBoolean(id, val) {
    this.tableData[id] = JSON.parse(val);
  }
  buildMetaSetting(data, parent = undefined){
    let dataObject = [];
    let tempObject = [];
    let tabObject = [];
    let mainIndex = 0;
    let index = 0;
    for (let col of data.fields){
      if((col.editable || !col.generated) && col.type !== 'object'){
        col['parent'] = parent;
        if(parent){
          col['inputName'] = col['parent'] + col['name'];
        }
        tempObject.push(col);
        index++;
        if(index%5===0 && mainIndex+1 !== data.fields.length){
          dataObject.push(tempObject);
          tempObject = [];
        }
        // else if (mainIndex+1 === data.fields.length){
        //   dataObject.push(tempObject);
        //   tempObject = [];
        // }
      }else if (col.type === 'object'){
        tabObject.push({name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name)});
      }
      if (mainIndex+1 === data.fields.length){
        dataObject.push(tempObject);
        tempObject = [];
      }
      mainIndex++;
    }
    this.metaFieldTabsSettingTemp = tabObject;
    return dataObject;
  }
  onFormSubmit(formData){
    this.hasFormErrors = false;
    this.isSubmitted = true;
		const controls = formData.controls;
    // console.log('this.tableSetting', this.tableSetting);
    // console.log('this.tableData',this.tableData);
		/** check form */
    if (formData.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
      this.layoutUtilsService.showNotification('Please fill in the required fields' , 'Dismiss');
			return;
		}
    if (!this.validateObject(this.tableData)) {
			this.hasFormErrors = true;
      this.layoutUtilsService.showNotification('Please fill in the required fields' , 'Dismiss');
			return;
		}
    let responseData = this.cleanResponseData(this.tableData, this.tableSetting.fields);
    console.log('responseData', responseData);
    if(this.userId){
      this.returnDataObjectEdit.emit(responseData);
    }else{
      this.returnDataObjectCreate.emit(responseData);
    }
  }
  private cleanResponseData(data, fields) {
    let newObj = {};
    for (let col of fields) {
      if ((col.editable || !col.generated) && col.type !== 'action' && col.visible) {
        if(data.hasOwnProperty(col.name)){
          if (col.type === 'reference') {
            if(data[col.name]['uid'] !== '')
              newObj[col.name] = data[col.name];
          } else if(col.type === 'object') {
              newObj[col.name] = this.cleanResponseData(data[col.name], col.fields) ;
          } else if(col.type === 'boolean') {
            newObj[col.name] = data[col.name] || false;
          } else {
            newObj[col.name] = data[col.name] || '';
          }
        }
      }
    }
    return newObj;
  }
  private validateObject(data) {
    for (let col of this.tableSetting.fields) {
      if ((!col.nullable && !col.generated) && col.type !== 'action') {
        if (col.type === 'reference') {
          if (data && data[col.name] && data[col.name]['uid'] === '') {
            console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }else if(col.type === 'tags'){
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }else{
          if (data && (data[col.name] === '' || data[col.name] === undefined)) {
            console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }
      }
    }
    return true;
  }
  private goBack(data) {
    this.returnAction.emit({action: 'back'});
  }
  ondelete(){
    if(this.userId)
      this.returnDataObjectDelete.emit(this.userId);
  }
  public initializeUser() {
    let newObj = {};
    for (let col of this.tableSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action' && col.visible) {
        if (col.type === 'reference') {
          newObj[col.name] = { name: '', uid: ''};
        } else if(col.type === 'object') {
          // do nothing
        } else if(col.type === 'boolean') {
          newObj[col.name] = false;
        } else {
          newObj[col.name] = '';
        }
      }
    }
    this.tableData = Object.assign({}, newObj);
    this.tableDataTemp = Object.assign({}, newObj);
  }
  public buildTabsMetaSetting() {
    this.metaFieldTabsSetting = undefined;
    let metaFieldTabsSetting = [];
    if(this.tableData){
      for (let tab of this.metaFieldTabsSettingTemp){
        if(!this.tableDataTemp.hasOwnProperty(tab.name)){
          if(this.tableData.hasOwnProperty(tab.name)){
            delete this.tableData[tab.name];
          }
        }
        if(this.tableDataTemp.hasOwnProperty(tab.name) || this.hasTargetRole(tab.name)){
          if(!this.tableData.hasOwnProperty(tab.name)){
            this.tableData[tab.name] = {}
          }
          metaFieldTabsSetting.push(tab);
        }
      }
    }
    this.metaFieldTabsSetting = metaFieldTabsSetting;
    // console.log('tableDataTemp', this.tableDataTemp);
    // console.log('tableData', this.tableData);
    // console.log('metaFieldTabsSetting', this.metaFieldTabsSetting);
  }
  public loadUser() {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.getSingleData(this.dataType, this.userId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        if (data) {
          // console.log(data);
          this.tableDataTemp = Object.assign({}, data.results);
          this.tableData = Object.assign({}, data.results);
          // console.log('this.tableData', this.tableData);
          this.buildTabsMetaSetting();
          // this.tableData = data.data;
        } else {
          this.tableDataTemp = undefined;
          this.tableData = undefined;
        }
        this.loading = false;
        this.loaderService.display(false);
      });
    }
  }
}
