import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { RequestService, MenuConfigService, StoreService } from '../../../shared/services';

import { ModalUserViewDialogComponent } from '../../../shared/components/custom-user-view-dialog/custom-user-view-dialog.component';
import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from '@angular/material';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public title = environment.serverName;
  public selectedRole = undefined;
  public pushRightClass: string;
  public userType: string = 'default';

  constructor(private requestService: RequestService, public router: Router, private translate: TranslateService,
    public dialog: MatDialog, private menuConfigService: MenuConfigService, public store: StoreService) {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
        this.toggleSidebar();
      }
    });
    this.userType = this.requestService.getUserType();
  }

  ngOnInit() {
    this.pushRightClass = 'push-right';
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        // console.log('currentUserSubject', data);
        if (data) {
          this.selectedUser = data;
        }
      })
    );
    this.subscriptions.push(
      this.requestService.currentUserRole.subscribe((data) => {
        // console.log('currentUserRole', data);
        if (data) {
          this.selectedRole = data;
        }
      })
    );
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }
  switchRole(role) {
    this.router.navigateByUrl('/dashboard').then(() => {
      // if(status){
      let savedRole = JSON.parse(JSON.stringify(role));
      delete savedRole.pageRights;
      this.store.set('selectedrole', savedRole);
      setTimeout(() => {
        window.location.reload();
      }, 300);
      // }
    });
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  onLoggedout() {
    this.requestService.logout();
  }

  changeLang(language: string) {
    this.translate.use(language);
  }
  viewProfile() {
    const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
      width: '1600px',
      data: {
        dataType: 'user',
        title: 'View',
        profile: true,
        data: { uid: this.selectedUser.uid },
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result);
      }
    });
  }

}
