import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, ElementRef, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { SnackBarComponent } from '../../../shared/components/snack-bar/snack-bar.component';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';

import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { RequestService, LayoutUtilsService, LoaderService, StoreService } from '../../../shared/services';
import { MyErrorStateMatcher } from '../custom-form/custom-form.component';
import { ConfirmEntityDialogComponent } from '../modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { ModalGalleryDialogComponent } from '../custom-gallery-dialog/custom-gallery-dialog.component';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { ModalMultipleAttachmentsDialogComponent } from '../custom-multiple-attachments-dialog/custom-multiple-attachments-dialog.component';
import { environment } from '../../../../environments/environment';

export class MyUserErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public selectedrole: any = undefined;
  public canSeeAttachments: boolean = false;
  public canEditAttachments: boolean = false;
  public attachmentsCount: any = {};
  private customKeys: any = environment.customKeys; // used in view
  private customDisplayKeys: any = environment.customDisplayKeys; // used in view
  public permission: any[] = [environment.customKeys.roleAdmin];
  public allowedExtensions: string[] = ['jpeg', 'jpg', 'bmp', 'png'];
  public allowedTranscriptExtensions: string[] = ['pdf'];
  public metaFieldSetting = undefined;
  public metaFieldTabsSetting = [];
  public errorMessage: string = '';
  public pictureLink: string = 'assets/images/profile.jpg';
  public hasFormErrors: boolean = false;
  public isStudent: boolean = false;
  public isSubmitted: boolean = false;
  public oldRolesList: any[] = [];
  public oldRoles: string = undefined;
  // public oldRole: string = '';
  public normalDisplay: string[] = ['string', 'integer', 'phone', 'number', 'email', 'textarea', 'text'];
  public competenciesDataSource: any[] = [];
  // public competenciesDataSourceObject: any = {};
  public competenciesDisplayedColumns: string[] = ['benchmarkId', 'year1', 'year2', 'year3', 'year4_5'];
  public competencylegaciesDisplayedColumns: string[] = ['Rater', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'];
  public competencylegaciesDisplayedColumnsNoRater: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'];
  public competencyLegacyData = { ca: [], fr: [], ir: [], pr: [] };
  public transcriptDisplayedColumns: string[] = ['year1', 'year2', 'year3', 'year4_5'];
  public transcriptData: any[] = [{
    year1: {
      fileLink: undefined,
      name: "",
      year: "year1"
    }, year2: {
      fileLink: undefined,
      name: "",
      year: "year2"
    }, year3: {
      fileLink: undefined,
      name: "",
      year: "year3"
    }, year4_5: {
      fileLink: undefined,
      name: "",
      year: "year4_5"
    }
  }];
  public evaluationLetterDisplayedColumns: string[] = ['title', 'year1', 'year2', 'year3', 'year4_5'];
  public evaluationLetterData: any[] = [
    {
      title: {
        name: 'Evaluation Letter'
      },
      year1: {
        name: 'First Year', fileLink: undefined, year: "year1"
      }, year2: {
        name: '2nd Year', fileLink: undefined, year: "year2"
      }, year3: {
        name: '3rd Year', fileLink: undefined, year: "year3"
      }, year4_5: {
        name: '4-5th Year', fileLink: undefined, year: "year4_5"
      }
    }
  ];

  // @Input() loading: boolean = false;
  public loading: boolean = false;
  public esMatcher = new MyUserErrorStateMatcher();
  // @Input() user: boolean = false;
  tableData: any = undefined;
  tableDataOriginal: any = undefined;
  tableDataTemp: any = undefined;
  private dataType: string = 'user';
  @Input() profileDisplay: boolean = true;
  @Input() userId: string = undefined;
  @Input() preselectedRoleId: string = undefined;
  private _tableSetting: any = undefined;
  @Input()
  set tableSetting(tableSetting: any) {
    this._tableSetting = tableSetting;
    // console.log('tableSetting', tableSetting);
    if (tableSetting) {
      this.metaFieldSetting = this.buildMetaSetting(tableSetting);
      // console.log('metaFieldSetting', this.metaFieldSetting);
      if (this.userId) {
        this.loadUser();
      } else {
        this.initializeUser();
      }
    }
  }
  get tableSetting(): any {
    return this._tableSetting;
  }
  @Output() returnDataObjectCreate = new EventEmitter<any>();
  @Output() returnDataObjectEdit = new EventEmitter<any>();
  @Output() returnDataObjectDelete = new EventEmitter<any>();
  @Output() returnAction = new EventEmitter<any>();

  @ViewChild('saveBtn') saveBtn: ElementRef;
  constructor(
    private loaderService: LoaderService,
    private requestService: RequestService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar, public store: StoreService, private permissionsService: NgxPermissionsService,
    private layoutUtilsService: LayoutUtilsService,
    private changeDetectorRefs: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.selectedrole = this.store.get('selectedrole');
          this.canSeeAttachments = this.canSeeAttachment();
          this.canEditAttachments = this.canEditAttachment();
        }
      })
    );
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  private returnObjectOfArray(uid) {
    for (let cmp of this.tableDataTemp['competencies']) {
      if (cmp.benchmarkId.uid === uid) {
        return cmp;
      }
    }
    return undefined;
  }
  public hasUidRole(uid) {
    if (this.tableData['roles']) {
      for (let rl of this.tableData['roles']) {
        if (rl.uid === uid) {
          return true;
        }
      }
    }
    return false;
  }
  public hasTargetRole(targetfield) {
    if (this.tableData['roles']) {
      for (let rl of this.tableData['roles']) {
        if (rl.targetfield === targetfield) {
          return true;
        }
      }
    }
    return false;
  }
  public buildCompetenciesData(callback = undefined) {
    if ((this.hasUidRole(this.customKeys.roleSchoolStudent) || this.tableData.hasOwnProperty('schoolstudent')) && this.tableData['program']['uid'] !== '') {
      this.requestService.getDataL('benchmark', (data, error) => {
        if (error) {
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        if (data) {
          this.competenciesDataSource = Object.assign([], data.results);
          let competencyObj = [];
          for (let cmp of this.competenciesDataSource) {
            if (cmp.program.includes(this.tableData['program']['uid'])) {
              let itemObj = { benchmarkId: cmp, year1: '', year2: '', year3: '', year4_5: '' };

              if (!this.tableDataTemp.hasOwnProperty('competencies') || (this.tableDataTemp.hasOwnProperty('competencies') && this.tableDataTemp['competencies'].length === 0)) {
                competencyObj.push({ benchmarkId: cmp, year1: '', year2: '', year3: '', year4_5: '' });
              } else {
                let itemInObj = this.returnObjectOfArray(cmp.uid);
                if (itemInObj) {
                  itemInObj['benchmarkId'] = cmp;
                  competencyObj.push(itemInObj);
                } else {
                  competencyObj.push(itemObj);
                }
              }
            }
          }
          this.tableData['competencies'] = JSON.parse(JSON.stringify(competencyObj));
          // this.tableDataTemp['competencies'] = JSON.parse(JSON.stringify(competencyObj));
        } else {
          this.competenciesDataSource = [];
        }
        if (callback) {
          callback();
        }
      }, true);
    } else {
      this.tableData['competencies'] = [];
      if (callback) {
        callback(true);
      }
    }
  }
  convertArrayToObject(arr: any[]) {
    let obj = {};
    for (let a of arr) {
      obj[a.uid] = a;
    }
    return obj;
  }
  private goBack(data) {
    this.returnAction.emit({ action: 'back' });
  }
  public setInternshipAttribute(id, val) {
    if (val) {
      this.tableData['internshipobj'] = {};
    } else {
      this.tableData['internshipobj'] = null;
    }
  }
  public setPracticumAttribute(id, val) {
    if (val) {
      this.tableData['practicumobj'] = {};
    } else {
      this.tableData['practicumobj'] = null;
    }
  }
  public setTWAttribute(id, val) {
    if (this.tableData['schoolstudent']['terminated'] || this.tableData['schoolstudent']['withdrew']) {
      this.tableData['schoolstudent']['withdrew_terminated'] = true;
    } else {
      this.tableData['schoolstudent']['withdrew_terminated'] = false;
    }
    // if (val) {
    //   this.tableData['practicumobj'] = {};
    // } else {
    //   this.tableData['practicumobj'] = null;
    // }
  }
  // public setAttributeProgram(id, val) {
  //   // console.log(id, val);
  //   this.tableData[id] = val;
  //   this.buildCompetenciesData();
  // }
  public setAttribute(id, val) {
    // console.log(id, val);
    this.tableData[id] = val;
  }
  public setTableAttribute(parentId, index, id, val) {
    this.tableData[parentId][index][id] = val;
  }
  public setParentAttribute(parentId, id, val) {
    // console.log(id, val);
    this.tableData[parentId][id] = val;
  }
  public setDateAttribute(id, val) {
    // console.log('setDateAttribute', id, val.toISOString());
    try {
      this.tableData[id] = val.toISOString();
    } catch (e) {
      // error
    }
  }
  public setParentDateAttribute(parentId, id, val) {
    try {
      this.tableData[parentId][id] = val.toISOString();
    } catch (e) {
      // error
    }
  }
  public setParentMultipleReferenceAttribute(parentId, id, val) {
    this.tableData[parentId][id] = val;
  }
  public setMultipleReferenceAttribute(id, val) {
    this.tableData[id] = val;
  }
  public setMultipleTableReferenceAttribute(id, val) {
    this.tableData[id] = val;
  }
  public setParentMultipleTableReferenceAttribute(parentId, id, val) {
    this.tableData[parentId][id] = val;
  }
  public setRoleMultipleReferenceAttribute(parentId, val) {
    this.tableData[parentId] = val;
    this.buildTabsMetaSetting();
    this.loadOldRoles();
    if (!(this.hasUidRole(this.customKeys.roleSchoolStudent) || this.isStudent)) {
      this.setPracticumAttribute(this.metaFieldSetting['practicum'].name, false);
      this.setInternshipAttribute(this.metaFieldSetting['internship'].name, false);
      this.tableData[this.metaFieldSetting['practicum'].name] = false;
      this.tableData[this.metaFieldSetting['internship'].name] = false;
    }
    // console.log(this.tableData);
  }
  public setReferenceAttribute(parentId, id, val) {
    // console.log(parentId, id, val);
    this.tableData[parentId][id] = val;
    if (parentId === 'program') {
      this.buildCompetenciesData();
    }
    // console.log(this.tableData);
  }
  public setParentReferenceAttribute(superparentId, parentId, id, val) {
    // console.log(superparentId, parentId, id, val);
    this.tableData[superparentId][parentId][id] = val;
    // console.log(this.tableData);
  }
  // public setRoleReferenceAttribute(parentId, val) {
  //   // console.log(parentId, id, val);
  //   this.tableData[parentId] = val;
  //   this.buildTabsMetaSetting() ;
  //   this.loadOldRoles();
  //   // console.log(this.tableData);
  // }
  public setAttributeBoolean(id, val) {
    this.tableData[id] = JSON.parse(val);
  }
  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    let tabObject = [];
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      } else if (col.type === 'object') {
        dataObject[col.name] = this.buildMetaSetting(col);
        tabObject.push({ name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name) });
      } else if (col.type === 'table') {
        dataObject[col.name] = this.buildMetaSetting(col);
      }
    }
    this.metaFieldTabsSetting = tabObject;
    return dataObject;
  }
  onFormSubmit(formData) {
    this.hasFormErrors = false;
    this.isSubmitted = true;
    const controls = formData.controls;
    // console.log('this.tableSetting', this.tableSetting);
    // console.log('this.tableData',this.tableData);
    /** check form */
    if (formData.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      this.layoutUtilsService.showNotification('Please fill in the required fields', 'Dismiss');
      return;
    }
    if (!this.validateObject(this.tableData, this.tableSetting.fields)) {
      this.hasFormErrors = true;
      this.layoutUtilsService.showNotification('Please fill in the required fields', 'Dismiss');
      return;
    }
    // console.log('this.tableData', this.tableData);
    let responseData = this.cleanResponseData(this.tableData, this.tableSetting.fields);
    if (this.userId) {
      // if(this.oldRole === responseData['role']['uid']){
      this.returnDataObjectEdit.emit(responseData);
      // }else{
      //   const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
      //       width: '300px',
      //       data: {
      //         title: 'Confirm Submission',
      //         description: 'Are you sure you want to change the role from ' + this.customDisplayKeys[this.oldRole] + ' to ' + this.customDisplayKeys[responseData['role']['uid']],
      //         cancelbtn: 'Cancel',
      //         confirmbtn: 'Confirm'
      //       }
      //     });
      //   dialogRef.afterClosed().subscribe(result => {
      //     if (result) {
      //       console.log('result', result);
      //       if(result){
      //         this.returnDataObjectEdit.emit(responseData);
      //         this.oldRole = responseData['role']['uid'];
      //       }
      //     }
      //   });
      // }
    } else {
      this.returnDataObjectCreate.emit(responseData);
    }
  }
  private cleanResponseData(data, fields) {
    let newObj = {};
    for (let col of fields) {
      if ((col.editable || !col.generated) && col.type !== 'action' && col.visible) {
        if (data && data.hasOwnProperty(col.name)) {
          if (col.type === 'reference') {
            if (col.reference.kind === 'multiple') {
              if (data[col.name].length > 0)
                newObj[col.name] = data[col.name];
            } else {
              if (data[col.name]['uid'] !== '')
                newObj[col.name] = data[col.name];
            }
          } else if (col.type === 'object') {
            newObj[col.name] = this.cleanResponseData(data[col.name], col.fields);
          } else if (col.type === 'table') {
            newObj[col.name] = [];
            for (let dataTable of data[col.name]) {
              newObj[col.name].push(this.cleanResponseData(dataTable, col.fields));
            }
          } else if (col.type === 'boolean') {
            newObj[col.name] = data[col.name] || false;
          } else {
            newObj[col.name] = data[col.name] || '';
          }
        }
      }
    }
    return newObj;
  }
  private validateObject(data, fields) {
    for (let col of fields) {
      if (col.type !== 'action') {
        if (col.type === 'object') {
          if (data.hasOwnProperty(col.name) && data[col.name]) {
            if (!this.cleanResponseData(data[col.name], col.fields)) {
              return false;
            }
          }
        } else if (col.type === 'table') {
          if (data.hasOwnProperty(col.name) && data[col.name]) {
            for (let dataTable of data[col.name]) {
              if (!this.cleanResponseData(dataTable, col.fields)) {
                return false;
              }
            }

          }
        } else {
          if ((!col.nullable && !col.generated)) {
            if (col.type === 'reference') {
              if (col.reference.kind === 'multiple') {
                // console.log('col.name', col.name, data[col.name] );
                if (data && data[col.name] && data[col.name].length === 0) {
                  return false;
                }
              } else {
                if (data && data[col.name] && data[col.name]['uid'] === '') {
                  // console.log('col.name', col.name, data[col.name] );
                  return false;
                }
              }
            } else if (col.type === 'tags') {
              if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
                // console.log('col.name', col.name, data[col.name] );
                return false;
              }
            } else {
              if (data && (data[col.name] === '' || data[col.name] === undefined)) {
                // console.log('col.name', col.name, data[col.name] );
                return false;
              }
            }
          }

        }
      }
    }
    return true;
  }
  ondelete() {
    if (this.userId)
      this.returnDataObjectDelete.emit(this.userId);
  }
  public initializeUser() {
    let newObj = {};
    for (let col of this.tableSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action' && col.visible) {
        if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            newObj[col.name] = [];
            // if(col.name === 'roles' && this.preselectedRoleId){
            //   newObj[col.name] = [{name: "Student", uid: "RL-UkwtNA==", order: 1, targetfield: "schoolstudent"}];
            // }
          } else {
            newObj[col.name] = { name: '', uid: '' };
          }
        } else if (col.type === 'object') {
          // do nothing
        } else if (col.type === 'table') {
          newObj[col.name] = [];
        } else if (col.type === 'boolean') {
          newObj[col.name] = false;
        } else {
          newObj[col.name] = '';
        }
      }
    }
    this.tableData = Object.assign({}, newObj);
    this.tableDataTemp = Object.assign({}, newObj);
    // this.tableDataOriginal = Object.assign({}, newObj);
    this.buildCompetenciesData(() => {
      this.loadOldRolesList(() => {
        this.tableDataOriginal = JSON.parse(JSON.stringify(this.tableData)); // keep this and the above
      });
    });
  }
  public initializeUserRoleData(fld) {
    let newObj = {};
    for (let col of this.tableSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action' && col.visible) {
        if (col.type === 'object' && col.name === fld) {
          for (let subcol of col.fields) {
            if ((subcol.editable || !subcol.generated) && subcol.type !== 'action' && subcol.visible) {
              if (subcol.type === 'reference') {
                if (subcol.reference.kind === 'multiple') {
                  newObj[subcol.name] = [];
                } else {
                  newObj[subcol.name] = { name: '', uid: '' };
                }
              } else if (subcol.type === 'object') {
                // do nothing
              } else if (subcol.type === 'table') {
                newObj[subcol.name] = [];
              } else if (col.type === 'boolean') {
                newObj[subcol.name] = false;
              } else {
                newObj[subcol.name] = '';
              }
            }
          }
        }
      }
    }
    return newObj;
  }
  public buildTabsMetaSetting() {
    // console.log('this.metaFieldTabsSetting', this.metaFieldTabsSetting);
    if (this.tableData) {
      for (let tab of this.metaFieldTabsSetting) {
        if (!this.tableDataTemp.hasOwnProperty(tab.name)) {
          if (this.tableData.hasOwnProperty(tab.name)) {
            delete this.tableData[tab.name];
          }
        }
        if (this.tableDataTemp.hasOwnProperty(tab.name) || this.hasTargetRole(tab.name)) {
          if (!this.tableData.hasOwnProperty(tab.name)) {
            let roleObj = this.initializeUserRoleData(tab.name);
            // console.log(roleObj);
            this.tableData[tab.name] = roleObj;
          }
        }
      }
      if (!this.tableData.hasOwnProperty('schoolstudent') && !this.hasTargetRole('schoolstudent') && this.hasTargetRole('alumni')) {
        let roleObj = this.initializeUserRoleData('schoolstudent');
        this.tableData['schoolstudent'] = roleObj;
      }
    }
  }
  public buildInternshipData() {
    if (this.tableData['internship']) {
      if (!this.tableData['internshipobj']) {
        this.tableData['internshipobj'] = {};
      }
    } else {
      this.tableData['internshipobj'] = null;
    }
  }
  public buildPracticumData() {
    if (this.tableData['practicum']) {
      if (!this.tableData['practicumobj']) {
        this.tableData['practicumobj'] = {};
      }
    } else {
      this.tableData['practicumobj'] = null;
    }
  }
  public buildTranscriptData() {
    if (this.tableData['transcript']) {
      for (let itm of this.tableData['transcript']) {
        this.transcriptData[0][itm.year] = itm;
      }
    }
  }
  public buildEvaluationLetterData() {
    if (this.tableData['evaluationletter']) {
      for (let itm of this.tableData['evaluationletter']) {
        this.evaluationLetterData[0][itm.year] = itm;
      }
    }
  }
  public loadUser() {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.getSingleData(this.dataType, this.userId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        if (data) {
          this.reloadUser(data);
        } else {
          this.tableDataOriginal = undefined;
          this.tableData = undefined;
        }
        this.loading = false;
        this.loaderService.display(false);
      });
    }
  }
  reloadUser(data) {
    this.tableDataTemp = Object.assign({}, data.results);
    this.tableData = Object.assign({}, data.results);
    // this.tableDataOriginal = JSON.parse(JSON.stringify(this.tableData));
    this.buildattachmentsCount();
    this.buildTabsMetaSetting();
    this.buildCompetenciesData(() => {
      this.buildEvaluationLetterData();
      this.buildCompetenciesLegacyData();
      this.buildInternshipData();
      this.buildPracticumData();
      this.buildTranscriptData();
      this.loadOldRolesList(() => {
        if (this.tableDataTemp['pictureLink']) {
          this.pictureLink = this.tableDataTemp['pictureLink']['fileLink'];
        }
        this.tableDataOriginal = JSON.parse(JSON.stringify(this.tableData)); // keep this and the above
      });
    });
  }
  buildCompetenciesLegacyData() {
    if (this.tableData['competencylegacies']) {
      this.competencyLegacyData = { ca: [], fr: [], ir: [], pr: [] };
      for (let cmp of this.tableData['competencylegacies']) {
        cmp['time'] = cmp.ts.replace(' 00:00:00', '');
        if (cmp.SubTab === '1') {
          this.competencyLegacyData.ca.push(cmp);
        } else if (cmp.SubTab === '2') {
          this.competencyLegacyData.fr.push(cmp);
        } else if (cmp.SubTab === '3') {
          this.competencyLegacyData.ir.push(cmp);
        } else if (cmp.SubTab === '4') {
          this.competencyLegacyData.pr.push(cmp);
        }
      }
    } else {
      this.competencyLegacyData = { ca: [], fr: [], ir: [], pr: [] };
    }

  }
  resetPassword() {
    const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
      width: '300px',
      data: {
        title: 'Reset Password',
        description: 'Are you sure you want to reset the password for this user? An email will be sent to the user with a new autogenerated password.',
        cancelbtn: 'Cancel',
        confirmbtn: 'Reset'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log('result', result);
        if (result) {
          this.confirmResetPassword();
        }
      }
    });
  }
  confirmResetPassword() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.resetUserPassword(this.userId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        if (data) {
          this.layoutUtilsService.showNotification('Password has been reset successfully.', 'Dismiss');
        }
        this.loading = false;
      });
    }
  }
  selectImage(selectImage) {
    console.log('selectImage', selectImage);
  }
  /**
  *  @param target: trigger event
  *
  *  trigger read files browsed files
  */
  public galleryLibrary() {
    const dialogRef = this.dialog.open(ModalGalleryDialogComponent, {
      width: '1600px',
      disableClose: false,
      autoFocus: false,
      data: {
        title: 'Upload image',
        data: this.userId,
        currentImage: this.pictureLink
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log('ModalGalleryDialogComponent', result);
        this.pictureLink = result;
      }
    });
  }
  onBrowseFiles(target: any, type): void {
    this.readFiles(target.files, type);
  }

  /**
   *  @param files: list of browsed files
   *  @param index: iterator over browsed images
   *
   *  read files browsed by user
   */
  readFiles(files, type, index = 0): void {
    let reader = new FileReader();
    if (index in files) {
      let currentFile = { error: false, text: files[index].name.split('.')[0], id: files[index].id, originalFile: files[index], source_url: null };
      let fileExt = files[index].name.split('.').pop();
      const max_size = 5000000;
      const max_height = 600;
      const max_width = 800;
      if (files[index].size > max_size) {
        this.layoutUtilsService.showNotification('Maximum size allowed is ' + max_size / 1000000 + 'Mb', 'Dismiss');
      } else if (this.allowedExtensions.indexOf(fileExt) === -1) {
        currentFile.error = true;
        this.layoutUtilsService.showNotification('The file type is not allowed.', 'Dismiss');
      } else {
        this.readFile(files[index], reader, (event) => {
          var image = new Image();
          this.readImage(event, image, (imgresult) => {
            console.log('readImage', imgresult);
            var canvas = document.createElement("canvas");
            var context = canvas.getContext("2d");
            var ratio = Math.min(max_width / imgresult.width, max_height / image.height);

            canvas.width = image.width * ratio;
            canvas.height = image.height * ratio;
            context.drawImage(image,
              0,
              0,
              image.width,
              image.height,
              0,
              0,
              canvas.width,
              canvas.height
            );
            if (image.width > max_width || image.height > max_height) {
              currentFile['originalFile'] = this.dataURLtoFile(canvas.toDataURL(), currentFile.text + '.png');
            }
            this.continueUpload(currentFile);
          });
        });
      }
    } else {
      this.changeDetectorRefs.detectChanges();
    }
  }
  readFile(file, reader, callback): void {
    reader.onload = () => {
      callback(reader.result);
    }
    reader.readAsDataURL(file);
  }
  readImage(file, image, callback): void {
    image.onload = () => {
      callback(image);
    }
    image.src = file;
  }
  continueUpload(currentFile) {
    this.requestService.onUploadUserImage(currentFile, this.userId)
      .subscribe(
        (results: any) => {
          console.log('currentFile', currentFile);
          if (results['status']) {
            currentFile.source_url = results.results.url;
            this.pictureLink = results.results.url;
            this.layoutUtilsService.showNotification('Profile image uploaded successfully', 'Dismiss');
          } else {
            currentFile.error = true;
            this.layoutUtilsService.showNotification('Error:' + results['message'], 'Dismiss');
          }
          this.changeDetectorRefs.detectChanges();
        },
        (error: any) => {
          console.log('Error uploading file.', error);
          currentFile.error = true;
          this.layoutUtilsService.showNotification('Error: Error uploading file.', 'Dismiss');
          this.changeDetectorRefs.detectChanges();
        }
      );
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  makeid(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  /**
  *  @param target: trigger event
  *
  *  trigger read files browsed files
  */
  onBrowseTranscriptFiles(target: any, transcript): void {
    this.readTranscriptFiles(target.files, transcript);
  }

  /**
   *  @param files: list of browsed files
   *  @param index: iterator over browsed images
   *
   *  read files browsed by user
   */
  readTranscriptFiles(files, transcript, index = 0): void {
    // let reader = new FileReader();
    if (index in files) {
      let currentFile = { error: false, text: files[index].name.split('.')[0], id: files[index].id, originalFile: files[index], source_url: null };
      let fileExt = files[index].name.split('.').pop();
      if (this.allowedTranscriptExtensions.indexOf(fileExt) === -1) {
        currentFile.error = true;
        this.layoutUtilsService.showNotification('Error: File extension not supported', 'Dismiss');
      } else {
        this.requestService.onUploadUserTranscript(currentFile, this.userId, transcript)
          .subscribe(
            (results: any) => {
              console.log('currentTranscriptFile', results);
              if (results['status']) {
                currentFile.source_url = results.results.fileLink;
                this.transcriptData[0][transcript.year] = results.results;
                this.layoutUtilsService.showNotification('Transcript uploaded successfully', 'Dismiss');
              } else {
                currentFile.error = true;
                this.layoutUtilsService.showNotification('Error:' + results['message'], 'Dismiss');
              }
              this.changeDetectorRefs.detectChanges();
            },
            (error: any) => {
              console.log('Error uploading file.', error);
              currentFile.error = true;
              this.layoutUtilsService.showNotification('Error: Error uploading file.', 'Dismiss');
              this.changeDetectorRefs.detectChanges();
            }
          );
      }
    } else {
      this.changeDetectorRefs.detectChanges();
    }
  }
  /**
  *  @param target: trigger event
  *
  *  trigger read files browsed files
  */
  onBrowseEvaluationLetterFiles(target: any, attachment): void {
    this.readEvaluationLetterFiles(target.files, attachment);
  }

  /**
   *  @param files: list of browsed files
   *  @param index: iterator over browsed images
   *
   *  read files browsed by user
   */
  readEvaluationLetterFiles(files, attachment, index = 0): void {
    // let reader = new FileReader();
    if (index in files) {
      let currentFile = { error: false, text: files[index].name.split('.')[0], id: files[index].id, originalFile: files[index], source_url: null };
      let fileExt = files[index].name.split('.').pop();
      if (this.allowedTranscriptExtensions.indexOf(fileExt) === -1) {
        currentFile.error = true;
        this.layoutUtilsService.showNotification('Error: File extension not supported', 'Dismiss');
      } else {
        this.requestService.onUploadUserEvaluationLetter(currentFile, this.userId, attachment)
          .subscribe(
            (results: any) => {
              if (results['status']) {
                currentFile.source_url = results.results.fileLink;
                this.evaluationLetterData[0][attachment.year] = results.results;
                this.layoutUtilsService.showNotification('Attachment uploaded successfully', 'Dismiss');
              } else {
                currentFile.error = true;
                this.layoutUtilsService.showNotification('Error:' + results['message'], 'Dismiss');
              }
              this.changeDetectorRefs.detectChanges();
            },
            (error: any) => {
              console.log('Error uploading file.', error);
              currentFile.error = true;
              this.layoutUtilsService.showNotification('Error: Error uploading file.', 'Dismiss');
              this.changeDetectorRefs.detectChanges();
            }
          );
      }
    } else {
      this.changeDetectorRefs.detectChanges();
    }
  }
  deleteTranscript(transcript, year) {
    const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
      width: '300px',
      data: {
        title: 'Delete Transcript',
        description: 'Are you sure you want to delete ' + year + '?',
        cancelbtn: 'Cancel',
        confirmbtn: 'Delete'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result);
        if (result) {
          this.confirmDeleteTranscript(transcript, year);
        }
      }
    });
  }
  public confirmDeleteTranscript(transcript: any, year) {
    const _deleteMessage = year + ` Deleted Successfully.`;
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData('transcript', transcript.uid, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        this.loading = false;
        if (data) {
          this.transcriptData[0][transcript.year] = { fileLink: undefined, name: transcript.name, year: transcript.year };
          this.layoutUtilsService.showNotification(_deleteMessage, 'Dismiss');
        }
      });
    }
  }
  deleteEvaluationLetter(attachment, year) {
    const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
      width: '300px',
      data: {
        title: 'Delete Attachment',
        description: 'Are you sure you want to delete ' + year + '?',
        cancelbtn: 'Cancel',
        confirmbtn: 'Delete'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result) {
          this.confirmDeleteEvaluationLetter(attachment, year);
        }
      }
    });
  }
  public confirmDeleteEvaluationLetter(attachment: any, year) {
    const _deleteMessage = year + ` Deleted Successfully.`;
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData('evaluationletter', attachment.uid, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        this.loading = false;
        if (data) {
          this.evaluationLetterData[0][attachment.year] = { fileLink: undefined, name: attachment.name, year: attachment.year };
          this.layoutUtilsService.showNotification(_deleteMessage, 'Dismiss');
        }
      });
    }
  }
  public viewTranscript(transcript: any) {
    window.open(transcript.fileLink, '_blank');
  }
  public viewEvaluationLetter(transcript: any) {
    window.open(transcript.fileLink, '_blank');
  }
  public loadOldRolesList(callback = undefined) {
    this.errorMessage = '';
    this.requestService.getDataL('role', (data, error) => {
      if (error) {
        this.errorMessage = error;
      }
      if (data) {
        this.oldRolesList = data.results;
        if (!this.userId && this.preselectedRoleId) {
          for (let rl of this.oldRolesList) {
            if (rl.uid === this.preselectedRoleId) {
              this.setRoleMultipleReferenceAttribute('roles', [rl]);
            }
          }
        } else {
          this.loadOldRoles();
        }
      } else {
        this.oldRolesList = [];
      }
      if (callback) {
        callback();
      }
    }, true);
  }
  public loadOldRoles() {
    let arrList: string[] = [];
    this.isStudent = false;
    for (let rl of this.oldRolesList) {
      if (this.tableData.hasOwnProperty(rl.targetfield) && !this.hasUidRole(rl.uid)) {
        arrList.push(rl.name);
        if (this.customKeys.roleSchoolStudent === rl.uid) {
          this.isStudent = true;
        }
      }
    }
    this.oldRoles = arrList.join(', ');
  }
  public openAttachments(e, type, field1, field2) {
    if (!this.userId) {
      return;
    }
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }
    let title = 'Upload documents for ' + this.metaFieldSetting[field1][field2].displayName;
    if (type === 'topic') {
      title = this.metaFieldSetting[field1][field2].displayName;
    }
    const dialogRef = this.dialog.open(ModalMultipleAttachmentsDialogComponent, {
      width: '1200px',
      data: {
        dataType: this.dataType,
        title: title,
        data: [],
        canEdit: this.canEditAttachments,
        type: type,
        userId: this.userId,
        field1: field1,
        field2: field2
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log('result', result);
        // this.loadUser();
      }
    });
  }
  canSeeAttachment() {
    if ([environment.customKeys.roleAdmin, environment.customKeys.roleFaculty, environment.customKeys.roleSupervisor].includes(this.selectedrole.uid) || this.selectedUser.uid === this.userId) {
      return true;
    }
    return false;
  }
  canEditAttachment() {
    if ([environment.customKeys.roleAdmin].includes(this.selectedrole.uid)) {
      return true;
    }
    return false;
  }
  public buildattachmentsCount() {
    let attachmentsCount = {};
    if (this.tableData && this.tableData.docs) {
      for (let itm of this.tableData.docs) {
        if (!attachmentsCount.hasOwnProperty(itm.field)) {
          attachmentsCount[itm.field] = 0;
        }
        attachmentsCount[itm.field]++;
      }
    }
    this.attachmentsCount = attachmentsCount;
  }
}
