import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { BehaviorSubject, Observable, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, LoggerService, LoaderService, MenuConfigService, LayoutUtilsService, PageScopeService, StoreService } from './shared/services';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { SnackBarComponent } from './shared/components/snack-bar/snack-bar.component';
import { MenuConfig } from './menu.config';
import { ErrorEntityDialogComponent } from './shared/components/modals';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public showLoader: boolean = false;
  public showError: boolean = true;
  public selectedrole: any = undefined;
  private menuShell: any = { aside: { self: {}, items: [] } };
  private repeatIntervalSeconds: number = 120;
  private dataSeenOnce: boolean = false;
  private messageSeenOnce: boolean = false;
  constructor(
    private menuConfigService: MenuConfigService, private loaderService: LoaderService, public snackBar: MatSnackBar, private translate: TranslateService, private requestService: RequestService, private logger: LoggerService, private router: Router, private dialog: MatDialog, private layoutUtilsService: LayoutUtilsService, private pageScopeService: PageScopeService, public store: StoreService, private permissionsService: NgxPermissionsService
  ) {
    translate.setDefaultLang('en');
    if (localStorage.getItem('currentUser')) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.requestService.setToken(currentUser.token);
      this.requestService.currentUser = currentUser;
      this.getMe();
      // this.requestService.authenticated();
    }
  }
  ngOnInit() {
    this.subscriptions.push(
      this.requestService.authenticatedUser.subscribe((event: boolean) => {
        if (event && !this.dataSeenOnce) {
          this.getMe();
        }
      }
      ));
    this.subscriptions.push(interval(1000 * this.repeatIntervalSeconds).subscribe((x) => {
      this.validateMe();
      // this.requestService.authenticated();
      // if(this.messageSeenOnce === false){
      //   this.kc.isLoggedIn().then(
      //     (response) => {
      //       let authenticatedUser: boolean = response || false;
      //       if(!authenticatedUser){
      //         this.messageSeenOnce = true;
      //         this.subscriptions.forEach((s) => s.unsubscribe());
      //         this.openAlert('Your session ended', 'Note');
      //       }
      //     }
      //   ).catch(() => {
      //     this.messageSeenOnce = true;
      //     this.subscriptions.forEach((s) => s.unsubscribe());
      //     this.openAlert('Your session ended', 'Note');
      //   });
      // }
    }));
    this.subscriptions.push(
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationStart) {
          // Show loading indicator
          this.loaderService.display(false);
        }

        if (event instanceof NavigationEnd) {
          // Hide loading indicator
          this.pageScopeService.update(event.url);
        }

        if (event instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          console.log('NavigationError:', event.error);
        }
      })
    );
    this.subscriptions.push(
      this.logger.errorObject.subscribe((error) => {
        if (error) {
          // console.log('Global Error: ', error);
          // this.loaderService.display(false);
          if (this.showError && this.requestService.canError) {
            this.showError = false;
            this.openAlert('An Error occured:' + error);
          }
        }
      })
    );
    // this.subscriptions.push(
    //   this.loaderService.status.subscribe((val: boolean) => {
    //       this.showLoader = val;
    //   })
    // );
    this.subscriptions.push(
      this.requestService.currentUserRole.subscribe((data) => {
        if (data) {
          this.refreshRights(data);
        }
      })
    );
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          if (localStorage.getItem('store_' + data.uid)) {
            this.store.init('store_' + data.uid);
            this.selectedrole = this.store.get('selectedrole');
          }
          let selectedroleFound = false;
          if (this.selectedrole) {
            for (let rl of data.roles) {
              if (rl.uid === this.selectedrole.uid) {
                this.requestService.currentUserRole.next(rl);
                selectedroleFound = true;
                break;
              }
            }
          }
          if (!selectedroleFound) {
            for (let rl of data.roles) {
              if (rl.uid === environment.customKeys.roleAdmin) {
                this.selectRole(rl);
                selectedroleFound = true;
                break;
              }
            }
            if (!selectedroleFound) {
              this.selectRole(data.roles[0]);
            }
          }
        }
      })
    );
    this.menuConfigService.loadConfigs(this.menuShell);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  // public delete(id: any) {
  // 		const _title: string = 'User Delete';
  // 		const _description: string = 'Are you sure to permanently delete this user?';
  // 		const _waitDesciption: string = 'User is deleting...';
  //
  // 		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
  // 		dialogRef.afterClosed().subscribe(res => {
  // 			if (!res) {
  // 				return;
  // 			}
  //       console.log(res);
  // 		});
  // }
  public openAlert(message, title = 'Error') {
    const _title: string = title;
    const _description: string = message;

    const dialogRef = this.layoutUtilsService.errorElement(_title, _description);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      window.location.reload();
    });
  }
  public getMe() {
    if (!this.dataSeenOnce) {
      this.loaderService.display(true);
    }
    this.requestService.getMe((data, error) => {
      if (!this.dataSeenOnce) {
        this.dataSeenOnce = true;
        this.loaderService.display(false);
      }

      if (error) {
        this.requestService.logout();
        console.log(error);
      }
      if (data) {
        let userData = data;
        userData.token = this.requestService.getToken();
        localStorage.setItem('currentUser', JSON.stringify(userData));
        this.requestService.currentUser = userData;
        // let selectedrole = this.store.get('selectedrole');
        // let selectedroleFound = false;
        // if(selectedrole){
        //   for(let rl of data.roles){
        //     if(rl.uid === selectedrole.uid){
        //       this.menuConfigService.onRoleUpdated$.next(rl);
        //       selectedroleFound = true;
        //       break;
        //     }
        //   }
        // }
        // if(!selectedroleFound){
        //   this.selectRole(data.roles[0]);
        // }
      } else {
        this.dataSeenOnce = false;
        this.requestService.logout();
      }
    });
  }
  public validateMe() {
    this.requestService.getMe((data, error) => {
      if (error) {
        this.requestService.logout();
        console.log(error);
      }
      if (data) {
        // valid
      } else {
        this.requestService.logout();
      }
    });
  }
  public selectRole(selectedRole) {
    this.requestService.currentUserRole.next(selectedRole);
  }
  public refreshRights(selectedRole) {
    this.permissionsService.flushPermissions();
    this.permissionsService.addPermission(selectedRole.uid);
    let savedRole = JSON.parse(JSON.stringify(selectedRole));
    delete savedRole.pageRights;
    this.store.set('selectedrole', savedRole);
    this.menuShell.aside.items = selectedRole.pageRights;
    this.pageScopeService.init(selectedRole.pageRights);
    this.pageScopeService.update(this.router.url);
    this.menuConfigService.loadConfigs(this.menuShell);
  }
}
