import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';

export class My2ErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit {
  public errorMessage: string = '';
  public loading: boolean = false;
  selectData: Array<any> = [];
  /* pagination Info */
  pageSize = 100;
  pageNumber = 1;
  orderDir = 'asc';
  searchText = '';

  myControl = new FormControl();
  filteredData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  private _parentFormSubmitted = false;
  @Input() clearable: boolean = false;
  @Input() required: boolean = false;
  public esMatcher = new My2ErrorStateMatcher();
  @Input() displayName: string[] = ['name', 'text'];
  @Input() filters: any;
  @Input() itemName: string = '';
  @Input() placeholder: string = '';
  @Input() dataType: string;
  @Input() orderBy: string = 'name';
  @Input() canCash: boolean = true;
  @Input() apiTarget: string = 'list';
  @Input() value: any;
  // @Input() value: any = {uid: '', name: ''};
  // @Input()
  // set childValue(childValue: any){
  //   if (childValue && childValue.hasOwnProperty('uid')) {
  //     this.value = childValue['uid'];
  //   }else {
  //     this.value = undefined;
  //   }
  // }
  // get childValue(): any{
  //   return this.value;
  // }
  @Input()
  set parentFormSubmitted(parentFormSubmitted: boolean){
      this._parentFormSubmitted = parentFormSubmitted;
      if(parentFormSubmitted){
        this.form.onSubmit(undefined)
      }
  }
  get parentFormSubmitted(): boolean{
    return this._parentFormSubmitted;
  }
  @Output() onSelectReturn = new EventEmitter<any>();
  @Output() onRoleSelectReturn = new EventEmitter<any>();
  @ViewChild('dataForm') form: NgForm;
  constructor(
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService
  ) { }

  ngOnInit() {
    this.loadData();
  }
  public onSubmit() {
    // do nothing
  }
  private getSelectedItem(val){
    for(let itm of this.selectData){
      if(val === itm.uid){
        return itm
      }
    }
    return '';
  }
  public setAttribute(val) {
    let valT = undefined;
    if(this.apiTarget === 'search'){
      if(val)
        valT = val.uid;
      if(this.dataType === 'role'){
        let selectedItem = this.getSelectedItem(valT);
        this.onRoleSelectReturn.emit(selectedItem);
      }else{
        this.onSelectReturn.emit(valT);
      }
    }else{
      if(val)
        valT = val.value;
      if(this.dataType === 'role'){
        let selectedItem = this.getSelectedItem(valT);
        this.onRoleSelectReturn.emit(selectedItem);
      }else{
        this.onSelectReturn.emit(valT);
      }
    }
  }
  displayFn(data?: any): string | undefined {
    return data ? data.name : undefined;
  }
  public loadData() {
    if (this.apiTarget === 'search') {
      this.loadDataSearch();
    }else{
      this.loadDataList();
    }
  }
  public loadDataList() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getDataL(this.dataType, (data, error) => {
        if (error) {
          this.errorMessage = error;
        }
        if (data) {
          this.selectData = data.results;
        }else {
          this.selectData = [];
        }
        this.filteredData.next(this.selectData);
        this.loading = false;
      }, this.canCash);
    }
  }
  public loadDataSearch() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let filterConfiguration = this.filters;
      this.requestService.getDataListSummary(this.dataType, {page: this.pageNumber , term: this.searchText, perpage: this.pageSize, filter: filterConfiguration, orderbydir: this.orderDir, orderby: this.orderBy}, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        if (data) {
          this.selectData = data.results;
        } else {
          this.selectData = [];
        }
        this.selectData.unshift({uid: '', text: "Select " + this.placeholder});
        this.filteredData.next(this.selectData);
        this.loading = false;
      });
    }
  }
  termSearch(e): any {
    this.searchText = e.term;
    this.loadDataSearch();
	}
}
